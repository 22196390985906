import React, { useState, useEffect } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from 'recharts';
import { FormInput } from '../../../components';

const LogViewer = ({ logs }) => {
  const [filteredLogs, setFilteredLogs] = useState(logs);
  const [filterText, setFilterText] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(new Array(logs.length).fill(false));
  const [selectedRange, setSelectedRange] = useState({ startDate: null, endDate: null });
  let timeUnit = 0;
  useEffect(() => {
    // Filter logs based on filter text
    const filtered = logs.filter(log => log.content.toLowerCase().includes(filterText.toLowerCase()));
    setFilteredLogs(filtered);
  }, [filterText, logs]);

  const handleBarClick = (data, index) => {
    if (!data.payload) return;
    const selectedDate = new Date(data.payload.time);
    console.log('bar click: ' + data.payload.time + ' ' + selectedDate);
    if (!selectedRange.startDate || selectedRange.endDate) {
      setSelectedRange({ startDate: selectedDate, endDate: null });
    } else {
      setSelectedRange({ ...selectedRange, endDate: selectedDate });
    }
    // if (!selectedRange.startDate || (selectedRange.startDate && selectedRange.endDate)) {
    //   setSelectedRange({ startDate: data.date, endDate: null });
    // } else if (selectedRange.startDate && !selectedRange.endDate) {
    //   setSelectedRange({ ...selectedRange, endDate: data.date });
    // }
  };
  
  useEffect(() => {
    // Filter logs based on selected date range
    if (selectedRange.startDate && selectedRange.endDate) {
      const startDate = selectedRange.startDate;
      const endDate = selectedRange.endDate 
                     ? new Date(selectedRange.endDate.getTime() + timeUnit) // Adjust according to your time unit
                     : new Date(startDate.getTime() + timeUnit); // Only startDate is set, use its range
      // const startDate = selectedRange.startDate;
      // const endDate = new Date(selectedRange.endDate.getTime() + (10 * 60 * 1000)); // 10 minutes range
      const filtered = logs.filter(log => {
        const logDate = new Date(log.timestamp);
        return logDate >= startDate && logDate < endDate;
      });
      setFilteredLogs(filtered);
    } else {
      setFilteredLogs(logs);
    }
  }, [selectedRange, logs]);
  
  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen.map((isOpen, idx) => idx === index ? !isOpen : false));
  };

  // Function to handle opening code (placeholder)
  const openCode = (log) => {
    console.log('Open code for:', log);
  };

  // Function to calculate the time unit and prepare bar chart data
  const prepareBarChartData = () => {
    if (logs.length === 0) return [];

    const sortedLogs = [...logs].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    const startTime = new Date(sortedLogs[0].timestamp);
    const endTime = new Date(sortedLogs[sortedLogs.length - 1].timestamp);
    const timeDiff = endTime - startTime; // in milliseconds

    // Determine the time unit (10 minutes in milliseconds)
    const tenMinutes = 10 * 60 * 1000;
    // timeUnit is defined at function scope.
    timeUnit = timeDiff / 25 > tenMinutes ? tenMinutes : timeDiff / 25;

    // Aggregate logs based on the time unit
    const aggregatedData = {};
    sortedLogs.forEach(log => {
      const logTime = new Date(log.timestamp);
      const roundedTime = new Date(Math.floor(logTime.getTime() / timeUnit) * timeUnit);
      const key = roundedTime.toISOString();

      if (!aggregatedData[key]) {
        aggregatedData[key] = { time: key, count: 0 };
      }
      aggregatedData[key].count++;
    });

    return Object.values(aggregatedData);
  };

  // Prepare data for the bar chart
  const barChartData = prepareBarChartData();
  // Find the maximum count value
  const maxCount = Math.max(...barChartData.map(data => data.count));
  // Set Y-axis max to 10% more than the maximum count
  const yAxisMax = Math.ceil(maxCount + (maxCount * 0.10));

  // Inline styles
  const logTableContainerStyle = {
    maxHeight: '400px', // Adjust height to show around 40 lines
    overflowY: 'scroll'
  };

  const tableCompactStyle = {
    padding: '0.5rem' // Reduced padding for a more compact look
  };

  // Format function for the X-axis labels
  const formatXAxis = (tickItem) => {
    return new Date(tickItem).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Function to check if a bar's time is within the selected range
  const isInSelectedRange = (barTime) => {
    console.log('isInRange: ' + barTime + ' ' + new Date(barTime));
    if (!selectedRange.startDate) return false;
    const startTime = selectedRange.startDate;
    const endTime = selectedRange.endDate 
                  ? new Date(selectedRange.endDate.getTime() + (10 * 60 * 1000)) 
                  : new Date(startTime.getTime() + (10 * 60 * 1000));
    return new Date(barTime) >= startTime && new Date(barTime) < endTime;


    // if (selectedRange.startDate && !selectedRange.endDate) {
    //   return new Date(barTime) == selectedRange.startDate;
    // }
    // if (!selectedRange.startDate || !selectedRange.endDate) return false;
    // const startTime = selectedRange.startDate;
    // const endTime = new Date(selectedRange.endDate.getTime() + (10 * 60 * 1000));
    // return new Date(barTime) >= startTime && new Date(barTime) < endTime;
  };

  return (
    <div>
      {/* <p>Fetch logs for ingestion service </p> */}
      {/* Bar Graph */}
      <BarChart width={600} height={120} data={barChartData}
          onClick={(data, index) => handleBarClick(data.activePayload[0].payload, index)}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time"
            tickFormatter={formatXAxis} 
            tick={{ fontSize: 10 }} // Decrease the font size here
        />
        <YAxis domain={[0, yAxisMax]}
            tick={{ fontSize: 10 }} // Decrease the font size here
        />
        <Tooltip />
        <Bar dataKey="count" onClick={handleBarClick}>
          {barChartData.map((entry, index) => (
            <Cell 
              key={`cell-${index}`} 
              // fill={(index > 2) ? "#ff7300" : "#8884d8"} 
              fill={isInSelectedRange(entry.time) ? "#ff7300" : "#8884d8"} 
            />
          ))}
        </Bar>
        {/* <Bar 
          dataKey="count" 
          fill={(entry) => isInSelectedRange(entry.time) ? "#ff7300" : "#8884d8"} // Dynamic fill based on the range
          data={barChartData}
        /> */}
        {/* {barChartData.map((entry, index) => (
          <Bar 
            key={`bar-${index}`}
            dataKey="count" 
            fill={isInSelectedRange(entry.time) ? "#ff7300" : "#8884d8"} // Change color if in selected range
            data={[entry]}
          />
        ))} */}
        {/* <Bar dataKey="count" fill="#8884d8" onClick={handleBarClick}/> */}
      </BarChart>

      {/* Filter Bar */}
      <FormInput
        type="text"
        placeholder="Filter logs..."
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
      />

      {/* Number of Lines Matching Filter Criteria */}
      <div className='mt-1 ms-2'>{filteredLogs.length} log entries match the filter criteria.</div>

      {/* Log Table */}
      <div style={logTableContainerStyle}>
      <Table style={tableCompactStyle}>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Source</th>
            <th>Content</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map((log, index) => (
            <tr key={index}>
              <td style={{paddingBottom: '2px', paddingTop: '2px'}}>{new Date(log.timestamp).toLocaleString()}</td>
              <td style={{paddingBottom: '2px', paddingTop: '2px'}}>{log.source}</td>
              <td  style={{paddingBottom: '2px', paddingTop: '2px'}}>{log.content}</td>
              <td style={{paddingBottom: '2px', paddingTop: '2px'}}>
                <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
                  <Dropdown.Toggle variant="outline" className="arrow-none">
                    <i className="mdi mdi-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => openCode(log)}>Open Code</Dropdown.Item>
                    {/* Additional actions here */}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
    </div>
  );
};

export default LogViewer;
