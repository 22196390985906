import React from "react";

// eslint-disable-next-line no-unused-vars
import { Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import InteractiveWork from "../pages/rptless/trbshell/interactive/InteractiveWork";
import { CtxMain } from "../pages/rptless/ctx/CtxMain";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/rptless/account/Login"));

const ShareableResult = React.lazy(() =>
  import("../pages/rptless/trbshell/ShareableResultWrapper")
);

const Resources = React.lazy(() => import("../pages/rptless/resources"));

const Dashboard = React.lazy(() => import("../pages/rptless/dashboard"));

const ResourcesForm = React.lazy(() =>
  import("../pages/rptless/resources/ResourceForm")
);

const GrantList = React.lazy(() =>
  import("../pages/rptless/resources/GrantList")
);

const Trs = React.lazy(() =>
  import("../pages/rptless/trbshell/Trs")
);

const Effort = React.lazy(() => 
  import("../pages/rptless/efforts/EffortsPage")
);

const Wiki = React.lazy(() => 
  import("../pages/rptless/wiki")
);

const Automation = React.lazy(() => 
  import("../pages/rptless/automation")
);

const CommandSequence = React.lazy(() => 
  import("../pages/rptless/command-sequence")
);

const Settings = React.lazy(() => 
  import("../pages/rptless/settings")
);

const TrsPanel = React.lazy(() => 
  import("../pages/rptless/trs")
);

const ManageOrgnization = React.lazy(() => 
  import("../pages/rptless/settings/orgnization/ManageOrg")
);

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: Dashboard,
  route: PrivateRoute,
};

const effortRoutes = {
  path: "/efforts",
  name: "Efforts",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Effort,
  icon: "uil-file-check-alt"
};

const wikiRoutes = {
  path: "/wiki",
  name: "Wiki",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Wiki,
  icon: "uil-book-open"
};

const trsRoutes = {
  path: "/trs",
  name: "Trs",
  route: PrivateRoute,
  roles: ["Admin"],
  component: TrsPanel,
  icon: "uil-processor"
};

const commandSequenceRoutes = {
  path: "/automation",
  name: "Automation",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Automation,
  icon: "uil-data-sharing"
};

const automationRoutes = {
  path: "/commandSequence",
  name: "Command Sequence",
  route: PrivateRoute,
  roles: ["Admin"],
  component: CommandSequence,
  icon: "uil-data-sharing"
};

const resultRoutes = {
  path: "/executions",
  name: "Executions",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-clipboard-alt",
  children: [
    {
      path: "/executions/:executionId",
      name: "Shareable Result",
      component: ShareableResult,
      route: PrivateRoute,
    },
    {
      path: "/executions",
      name: "Shareable Result",
      component: ShareableResult,
      route: PrivateRoute,
    },
  ],
};

const resourcesRoutes = {
  path: "/resources",
  name: "Resources",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-clipboard-alt",
  children: [
    {
      path: "/resources/grants",
      name: "Resources",
      component: GrantList,
      route: PrivateRoute,
    },
    {
      path: "/resources/create",
      name: "Resources",
      component: ResourcesForm,
      route: PrivateRoute,
    },
    {
      path: "/resources/edit/:resourceId",
      name: "Resources",
      component: ResourcesForm,
      route: PrivateRoute,
    },
    {
      path: "/resources",
      name: "Resources",
      component: Resources,
      route: PrivateRoute,
    },
  ],
};

const TrsRoutes = {
  path: "/trshell",
  name: "Trs",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-clipboard-alt",
  children: [
    {
      path: "/trshell/v2",
      name: "Ctx",
      component: CtxMain,
      route: PrivateRoute,
    }, {
      path: "/trshell/interactive",
      name: "Interactive work",
      component: InteractiveWork,
      route: PrivateRoute,
    },
    {
      path: "/trshell/new",
      name: "Shareable Result",
      component: Trs,
      route: PrivateRoute,
    },
    {
      path: "/trshell/:instanceId",
      name: "Shareable Result",
      component: Trs,
      route: PrivateRoute,
    },
  ],
};

const settingRoutes = {
  path: "/settings",
  name: "Settings",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-cog",
  children: [
    {
      path: "/settings/org/:orgId",
      name: "Manage Org",
      component: ManageOrgnization,
      route: PrivateRoute,
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      route: PrivateRoute,
    },
  ],
};

const otherPublicRoutes = [];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    route: Route,
  },
];

// All routes
const authProtectedRoutes = [effortRoutes, resultRoutes, resourcesRoutes, TrsRoutes, wikiRoutes, automationRoutes, commandSequenceRoutes, trsRoutes, settingRoutes, rootRoute];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
