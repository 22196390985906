import { APICore } from "./apiCore";

const api = new APICore();

// get resource list by userid
function getResourceList(params) {
  const baseUrl = `/resources/user/${params.userId}`;
  return api.get(`${baseUrl}`, params);
}

// delete resource by id
function deleteResource(params) {
  const baseUrl = `/resources/${params.resourceId}`;
  return api.delete(`${baseUrl}`, params);
}

// Create resource
function createResource(params) {
  const baseUrl = `/resources`;
  return api.create(`${baseUrl}`, params);
}

// Update resource
function updateResource(params) {
  const baseUrl = `/resources`;
  return api.update(`${baseUrl}`, params);
}

// Get resource by id
function getResourceById(params) {
  const baseUrl = `/resources/${params.resourceId}`;
  return api.get(`${baseUrl}`, params);
}


export { createResource, deleteResource, getResourceById, getResourceList, updateResource };