import React, { useRef, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props): React$Element<any> => {
  const { data, xaxis, yaxis } = props;
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const gradientStroke = ctx.createLinearGradient(0, 500, 0, 150);
    gradientStroke.addColorStop(0, "#fa5c7c");
    gradientStroke.addColorStop(1, "#727cf5");
    return gradientStroke;
  }

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const xaxisIndex = data.colName.indexOf(xaxis);
    const yaxisIndex = data.colName.indexOf(yaxis);

    const labelData = data.rows.map(
      (item) => Object.values(item.data[xaxisIndex])[0]
    );
    const chartYData = data.rows.map(
      (item) => Object.values(item.data[yaxisIndex])[0]
    );

    const chartData = {
      labels: labelData,
      datasets: [
        {
          label: yaxis,
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
          borderColor: createGradient(chart.ctx, chart.chartArea),
          hoverBackgroundColor: createGradient(chart.ctx, chart.chartArea),
          hoverBorderColor: createGradient(chart.ctx, chart.chartArea),
          categoryPercentage: 0.5,
          barPercentage: 0.7,
          data: chartYData,
        },
      ],
    };

    setChartData(chartData);
  }, [data, xaxis, yaxis]);

  // options
  const barChartOpts = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#727cf5",
        titleFontColor: "#fff",
        bodyFontColor: "#fff",
        bodyFontSize: 14,
        displayColors: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
          color: "rgba(0,0,0,0.05)",
        },
        stacked: false,
        ticks: {
          stepSize: 20,
        },
      },
      x: {
        stacked: false,
        grid: {
          color: "rgba(0,0,0,0.05)",
        },
      },
    },
  };

  return (
    <div style={{ height: "320px" }} className="chartjs-chart">
      <Bar ref={chartRef} data={chartData} options={barChartOpts} />
    </div>
  );
};

export default BarChart;
