import { Button, Col, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { fetchJiraIssues, fetchJiraProjects } from "../../../../redux/actions";

const TemplateModal = (props) => {
  const {
    onCancel,
    shareToggle,
    onSubmit,
    modalType,
    setOperations,
    setSelJiras,
    opsData,
  } = props;
  const dispatch = useDispatch();
  const [project, setProject] = useState([]);
  const [jiraData, setJiraData] = useState([]);

  const jiraProjects = useSelector(
    (state) => state.ResourceReducer.jiraProjects || []
  );

  const jiraIssues = useSelector(
    (state) => state.ResourceReducer.jiraIssues || []
  );

  const handleProjectSelect = (selectedOption) => {
    setProject(selectedOption);
    dispatch(fetchJiraIssues({ projectId: selectedOption.value }));
  }
  useEffect(() => {
    dispatch(fetchJiraProjects({}));
  }, []);

  const getByTitle = () => {
    if (modalType === "jira") {
      return "Attach Jira";
    } else if (modalType === "result") {
      return "Attach Result";
    }
  };

  return (
    <Modal show={shareToggle} onHide={onCancel} centered>
      <Modal.Header
        className="share-modal-header"
        closeButton
        onHide={onCancel}
      >
        <Modal.Title>{getByTitle()}</Modal.Title>
      </Modal.Header>
      <div className="p-1">
        <Modal.Body className="px-3 pt-2 pb-0">
          <Form.Group as={Col} controlId="formGridState" className="mb-3">
            <div className="col-12">
              {modalType === "jira" && (
                <>
                  <div className="d-flex align-items-center w-auto mb-3">
                    <div className="me-2" style={{ width: 75 }}>
                      Project:
                    </div>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 300,
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 300,
                        }),
                      }}
                      isMulti={false}
                      options={jiraProjects?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      className="react-select"
                      classNamePrefix="react-select"
                      onChange={handleProjectSelect}
                    ></Select>
                  </div>
                  {project?.value && (
                    <div className="d-flex align-items-center w-auto">
                      <div className="me-2" style={{ width: 75 }}>
                        Jira Tickets:
                      </div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 300,
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 300,
                          }),
                        }}
                        isMulti={true}
                        options={jiraIssues?.issues?.map((item) => {
                          return { value: item.id, label: item.key };
                        })}
                        className="react-select"
                        classNamePrefix="react-select"
                        onChange={(selectedOption) =>
                          setSelJiras(selectedOption)
                        }
                      ></Select>
                    </div>
                  )}
                </>
              )}
              {modalType === "result" && (
                <div className="d-flex align-items-center w-auto">
                  <div className="me-2" style={{ width: 75 }}>
                    OpIds:
                  </div>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: 300,
                      }),
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        width: 300,
                      }),
                    }}
                    isMulti={true}
                    options={opsData}
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => setOperations(selectedOption)}
                  ></Select>
                </div>
              )}
            </div>
          </Form.Group>
          <div className="py-2">
            <Button
              variant="primary"
              type="submit"
              className="me-1"
              onClick={onCancel}
            >
              Done
            </Button>
            <Button variant="light" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default TemplateModal;
