import React, { useState } from 'react';
import { Button, Col, Collapse, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';
import { constructCommandNode, getDefaultHostDetails } from '../../utils';
import { updateSdocAddNode } from '../../../../../redux/actions';
import { resourceNames } from '../../../data/constants';
import { FormInput, VerticalForm } from '../../../../../components';
import SimpleBar from "simplebar-react";

const noShadowButtonStyle = {
  padding: 0,
  margin: 0,
  color: 'grey',
  boxShadow: 'none', // Added to remove the glow
  outline: 'none' // Additionally removes the outline
};

const sampleHypothesisData = [
  {
    title: 'Spike in ingestion traffic for the customer',
    nodes: [
      { title: 'Get customer UUID', description: 'Get the customer UUID from the customer database using the customer name in the JIRA ticket',
        command: {
          'type': 'kShellCommand',
          "shellCommandDetails": {
            "command": 'SELECT CustomerUUID from customers where "${customerName}" ~ CustomerName"',
            "resourceId": "prod-customer-pg-id",
            "resourceName": "prod-customer-pg",
          }
        },
      },
      { title: 'Check Ingestion volume', description: 'Check the ingestion volume for the customer for the duration when the metrics are reported as broken',
        command: {
          'type': 'kShellCommand',
          "shellCommandDetails": {
            "command": `SELECT HourBucket, IngestedHourlyData 
            FROM HourlyActivityTracker 
            WHERE
              CustomerUUID="#{customerUUID}" AND
              StartDate="#{startDate}" AND EndData="#{endDate}"`,
            "resourceId": "prod-ingestion-activity-aurora-id",
            "resourceName": "prod-ingestion-activity-aurora",
          }
        }, 
      },
      { title: 'Check allocated resources for the customer', description: 'Check the resource allocation for the customer to see if the customer has been allocated enough resources to handle the spike in traffic. Query the internal API endpoint.',
        command: {
          'type': 'kShellCommand',
          "shellCommandDetails": {
            "command":
              `curl -X GET
                "192.128.101.16/api/resource-allocations?customerUUID=$\{customerUUID}"
                -H "Accept: application/json"`,
            "resourceId": "devops-bastion-host-id",
            "resourceName": "devops-bastion-host",
          }
        }, 
      }
    ]
  },
  {
    title: 'Ingestion service is dropping data for all customers',
    nodes: [
      { title: 'Check logs for ingestion service', description: 'Pull logs from Loki to see if there are any errors logged by the ingestion service',
        command: {
          'type': 'kShellCommand',
          "shellCommandDetails": {
            // "command": `loki query -q='{{namespace="ingestion-service" and level="error"}}'`, 
            "command": '{container="sanctum-analytics", namespace="loki-prod"} | = "ingestion.go"',
            "resourceId": "prod-loki-id",
            "resourceName": "prod-loki",
          }
        }, 
      },
      { title: 'Check Ingestion service CPU health', description: 'Check CPU utilization for the ingestion service VM deployed in AWS',
        command: {
          'type': 'kShellCommand',
          "shellCommandDetails": {
            "command":
                `aws cloudwatch get-metric-statistics \
                --namespace AWS/EC2 \
                --metric-name CPUUtilization \
                --dimensions Name=InstanceId,Value=i-01de7511fe7eab397 \
                --end-time $(date -u +"%Y-%m-%dT%H:%M:%SZ") --start-time $(date -u -v-1H +"%Y-%m-%dT%H:%M:%SZ") \
                --period 300 --statistics Average`,
            "resourceId": getDefaultHostDetails().hostId,
            "resourceName": getDefaultHostDetails().hostname,
          }
        }, 
      }
    ]
  }
];

// Styled components
const VerticalLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px;
  margin-left: 15px;
  margin-bottom: 5px;
`;

const Box = styled.div`
  width: 450px;
  height: auto;
  background-color: rgba(69, 77, 88, 0.1);
  display: flex;
  justify-content: left;
  align-items: left;
  border-radius: 4px;
  padding: 10px;
`;

const VerticalLine = styled.div`
  margin-left: 20px;
  border-left: 1px solid rgba(69, 77, 88, 0.3);
  height: 35px; /* Adjust height as needed */
`;

// TODO:
// Take a third paramter for the config. Call it options This will be used to pass the resource and target details.
// Use this to fill options for the resource and target dropdowns.
const ShellCommand = ({shellCommandDetails, onAdd, options}) => {
  console.log(options);
  return (
    <>
      <div className="p-1 rounded" style={{backgroundColor: 'rgb(49,58,70)'}}>
        <div className="w-100 p-1 rounded" style={{backgroundColor: 'rgba(49,58,70, 0.8)'}}>
          <code style={{color: '#dddddd'}}>{shellCommandDetails.command}</code>
        </div>

        {
          shellCommandDetails.saveAs ? (
            <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid grey', paddingTop: '5px' }}>
              <span style={{ color: 'grey', marginLeft: '8px' }}>save result as:</span>
              <input
                type="text"
                value={shellCommandDetails.saveAs}
                style={{
                  backgroundColor: 'rgba(250,250,250, 0.1)', // Slightly lighter than the surrounding box
                  border: 'none',
                  color: '#dddddd',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  marginLeft: '8px',
                  lineHeight: '8px',
                }}
              />
            </div>
          ) : (
            <></>
          )
        }        

      </div>
      <Row className="g-2">
          <Col md>
              <FloatingLabel controlId="floatingInputGrid" label="Select Resource:">
                  <Form.Select size="sm" aria-label="Floating label select example"
                    style={{backgroundColor: 'transparent', fontSize: '13px', border: '0px solid transparent'}} 
                    value={shellCommandDetails.resourceId}>
                      {
                        options.resources.map((resource, index) => {
                          return (
                            <option key={index}
                                    value={resource.resourceId}>{resource.resourceName}
                            </option>);
                        })
                      }
                      {/* <option>dev-bastion-host</option>
                      <option value="1">personal-mac-host</option>
                      <option value="3">staging-bastion-host</option> */}
                  </Form.Select>
              </FloatingLabel>
          </Col>
          <Col md>
              <FloatingLabel controlId="floatingSelectGrid" label="Select target shell">
                  <Form.Select  size="sm" aria-label="Floating label select example"
                    style={{backgroundColor: 'transparent', border: '0px solid transparent'}}>
                      {
                        options.targets && options.targets.map((target, index) => {
                          return <option key={index} value={target.targetId}>{target.targetName}</option>
                        })
                      }
                      <option>TrShell #f809</option>
                      <option value="1">TrShell #f810 </option>
                      <option value="2">Cloudshell #f812</option>
                  </Form.Select>
              </FloatingLabel>
          </Col>
          <Col xs='2'>
            <Button variant="link" onClick={() => { onAdd({resourceId: shellCommandDetails.resourceId})} }> Add </Button>
          </Col>
      </Row>

    </>
  );

}

const SuggestionNodeDetails = ({description, command}) => {
  const dispatch = useDispatch();
  const options = {
    resources: resourceNames,
  };

  const addCommand = (shellCommandDetails, config) => {
    const {resourceId, targetType, targetId} = config;
    const selectedResourceName = resourceNames.find(r => r.resourceId === resourceId).resourceName;
    const commandMainTextWithMentions = `@[${selectedResourceName}](${resourceId}) ${shellCommandDetails.command}`;
    const newCommandNode = constructCommandNode(resourceId, commandMainTextWithMentions, '');
    dispatch(updateSdocAddNode('1', newCommandNode));
  }
  return (<div className='mt-1 ms-1 me-1'>
    <p style={{fontSize:'13px', marginBottom: '5px'}}>
      {description}
    </p>
    <ShellCommand shellCommandDetails={command.shellCommandDetails} options={options}
        onAdd={(config) => addCommand(command.shellCommandDetails, config)}/>
  </div>);
}


const SuggestionNode = ({ title, description, command }) => {
  const [expanded, setExpanded] = useState(false);
  
  return (
    <div>
       
      <div>
        <Button variant="link" onClick={() => setExpanded(!expanded)} style={noShadowButtonStyle}>
          <i className={`mdi mdi-chevron-${expanded ? 'down' : 'right'}`}></i>
          {" "}{title}
        </Button>
      </div>
      {expanded && <SuggestionNodeDetails description={description} command={command}/>}
    </div>
  );
};

const Hypothesis = ({ title, nodes, index}) => {
  const [open, setOpen] = useState(true);
  return (
    <VerticalLineContainer>

      <div className='mb-2' style={{fontWeight: '700'}}>
        Potential cause {"#" + (index + 1)}:
        <span className='ms-1' style={{fontWeight: '400'}}> {title} </span><br/>
        <Button variant="link" onClick={() => setOpen(!open)} style={noShadowButtonStyle}>
          <i className={`mdi mdi-chevron-${open ? 'down' : 'right'}`}></i>
          {" "}Suggested commands to investigate
        </Button>
      </div>
      <Collapse in={open}>
        <div className='ms-2 mb-1'>
          {nodes.map((node, index) => (
            <React.Fragment key={index}>
              <Box>
                <SuggestionNode title={node.title} description={node.description} command={node.command}/>
              </Box>
              {index !== nodes.length - 1 && <VerticalLine />}
            </React.Fragment>
          ))}

        </div>
      </Collapse>
    </VerticalLineContainer>
  );
};

const sampleExtractedVars = [{
  name: 'customerName',
  description: 'Name of the customer',
  value: 'walmart',
  valueType: 'string',
}, {
  name: 'startDate',
  description: 'Start date for the time range',
  value: 'Jan 10th 2024 10:00:00',
  valueType: 'date',
}, {
  name: 'endDate',
  description: 'End date for the time range',
  value: 'Jan 10th 2024 14:00:00',
  valueType: 'date',
}];

const ExtractedVars = ({ vars }) => {
  const [open, setOpen] = useState(false);
  return (
    <VerticalLineContainer>

      <div className='mb-1'>
        <Button variant="link" onClick={() => setOpen(!open)} style={noShadowButtonStyle}>
          <i className={`mdi mdi-chevron-${open ? 'down' : 'right'}`}></i>
          {" "} Values
        </Button>
      </div>

      <Collapse in={open}>
        <div className='ms-2'>
          <VerticalForm>
            {
              vars.map((v, index) => (
                <FormInput
                    label={v.name}
                    name={v.name}
                    value={v.value}
                    containerClass={'ms-1 mb-2 me-2'}
                    size='sm'
                />
              ))
            }
          </VerticalForm>
        </div>
      </Collapse>
    </VerticalLineContainer>
  );
}

const HypothesisHeadingText = () => {
  return (
    <div className='mt-2 ms-3 me-2' style={{fontWeight: '400'}}>
      Based on the information provided, the assistant has identified the following potential causes for the issue.
      Some values have been extracted from JIRA that will be used in the suggested commands.
    </div>
  );
}

export const HypothesisList = ({ hypothesisData }) => {
  hypothesisData = hypothesisData || sampleHypothesisData;
  return (
    <div>
      <SimpleBar style={{ height: "630px", width: "100%" }}>
        <HypothesisHeadingText />
        <ExtractedVars vars={sampleExtractedVars} />
        {hypothesisData.map((hypothesis, index) => (
          <Hypothesis key={index} index={index} title={hypothesis.title} nodes={hypothesis.nodes} />
        ))}


      </SimpleBar>
    </div>
  );
};