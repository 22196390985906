import { TrsActionTypes } from "./constants";

// actions.js
export const addVarItem = (item) => ({
  type: TrsActionTypes.ADD_VAR_ITEM,
  payload: item,
});

export const getUserAndGroups = () => ({
  type: TrsActionTypes.GET_USER_GROUPS,
  payload: {},
});

export const userGroupApiResponseSuccess = (data) => ({
  type: TrsActionTypes.GET_USER_GROUPS_SUCCESS,
  payload: data,
});

export const userGroupApiResponseError = () => ({
  type: TrsActionTypes.GET_USER_GROUPS_ERROR,
  payload: [],
});

export const shareTrs = (params) => ({
  type: TrsActionTypes.SHARE_TRS,
  payload: params,
});

export const updateProfile = (params) => ({
  type: TrsActionTypes.UPDATE_PROFILE,
  payload: params,
});

export const createPlaybookAction = (params) => ({
  type: TrsActionTypes.CREATE_PLAYBOOK,
  payload: params,
});

export const apiResultTrsShell = (params) => ({
  type: TrsActionTypes.SAVE_API_RESULT,
  payload: params,
});
