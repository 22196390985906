import { UserActivityActionTypes } from "./constants";

const initialState = {
  userActivityList: [],
  userId: 1,
  users: []
};

const UserActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActivityActionTypes.GET_USER_ACTIVITY: {
      return {
        ...state,
        userId: action.payload.data,
      };
    }

    case UserActivityActionTypes.GET_USER_ACTIVITY_API_SUCCESS: {
      return {
        ...state,
        userActivityList: action.payload,
      };
    }

    case UserActivityActionTypes.GET_ALL_USER_API_SUCCESS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    default:
      return state;
  }
};

export default UserActivityReducer;
