// Action type for wsDoc - that holds details about the Ctx workspace.
export const UPDATE_WSDOC = 'UPDATE_WSDOC';

// Action type constants for sdocs
export const FETCH_SDOC = 'FETCH_SDOC';
export const UPDATE_SDOC_ADD_NODE = 'UPDATE_SDOC_ADD_NODE';
export const UPDATE_SDOC_DELETE_NODE = 'UPDATE_SDOC_DELETE_NODE';
export const UPDATE_SDOC_UPDATE_NODE = 'UPDATE_SDOC_UPDATE_NODE';
export const UPDATE_SDOC_ADD_VARS = 'UPDATE_SDOC_ADD_VARS';
export const UPDATE_SDOC_UPDATE_VARS = 'UPDATE_SDOC_UPDATE_VARS';
export const UPDATE_SDOC_IN_STORE = 'UPDATE_SDOC_IN_STORE';  // Does not update the backend.

// Action type constants for ops
export const ADD_OP = 'ADD_OP';
export const UPDATE_OP_STATUS = 'UPDATE_OP_STATUS';
export const FETCH_OP_STATUS = 'FETCH_OP_STATUS';