import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateSdocAddNode } from "../../../../redux/actions";
import { constructEmptyCommandNode } from "../utils";

export const TrShellSimpleTopToolbar = ({sdocId, selectedCmd, handleWorkspaceModal}) => {
  const dispatch = useDispatch();

  return (<>
        <div className="toolbar mb-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup>
                <Button variant="outine-secondary" size="sm"
                    onClick={() => dispatch(updateSdocAddNode(
                      sdocId,
                      constructEmptyCommandNode()
                    ))}
                    style={{
                      border: '1px solid #fefefe', //'1px solid rgb(240, 240, 240)',
                      fontSize: 'smaller',
                      height: '30px'}}
                >
                  <i className="mdi mdi-plus"></i> New Command
                </Button>
                <Dropdown className="float-end" align="end">
                  <Dropdown.Toggle
                    variant="button"
                    className="arrow-none btn btn-sm btn-light"
                    style={{
                      border: '1px solid #fefefe', //'1px solid rgb(240, 240, 240)',
                      fontSize: 'smaller',
                      height: '30px'}}
                  >
                    Actions <i className="mdi mdi-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item disabled={selectedCmd?.length === 0} onClick={handleWorkspaceModal}>
                    <i className="mdi mdi-circle-edit-outline me-1"></i> Create Playbook
                    </Dropdown.Item>                    
                  </Dropdown.Menu>
                </Dropdown>                
            </ButtonGroup>
        </div>
  </>);
}
