import { ChatActionTypes } from './constants';

const initialState = {
  messages: [],
  conversations: {},
};

const assistantChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ChatActionTypes.NEW_CONVERSATION:
      // Create a new conversation on the backend.
      // Send the relevant context with the conversation.
      // Optionally, send a user message along with the creation request.
      const { conversationContext, message } = action.payload;
      const messageList = message ? [message] : [];
      const newConversationId = action.payload.conversationId;
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [newConversationId]: messageList,
        }
      }

    case ChatActionTypes.ADD_MESSAGE_TO_CONVERSATION:
      const conversationId = action.payload.conversationId;
      const cMessages = state.conversations[conversationId] || [];
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [conversationId]: [...cMessages, action.payload.message]
        }
      };
    case ChatActionTypes.ADD_MESSAGE:
      if (state.messages.some(msg => msg.id === action.payload.id)) {
        return state;
      }
      return { ...state, messages: [...state.messages, action.payload] };
    default:
      return state;
  }
};

export default assistantChatReducer;
