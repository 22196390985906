// @flow
import { LayoutActionTypes } from "./constants";

import * as layoutConstants from "../../constants/layout";

const INIT_STATE = {
  layoutColor: layoutConstants.LAYOUT_COLOR_LIGHT,
  layoutType: layoutConstants.LAYOUT_VERTICAL,
  layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
  leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DARK,
  leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED,
  showRightSidebar: false,
  apiResult: null,
  msg: ""
};

type LayoutAction = { type: string, payload?: string | null };

type State = {
  layoutType: string,
  layoutColor: string,
  layoutWidth: string,
  leftSideBarTheme: string,
  leftSideBarType: string,
  showRightSidebar?: boolean,
};

const Layout = (state: State = INIT_STATE, action: LayoutAction): any => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case LayoutActionTypes.CHANGE_LAYOUT_COLOR:
      return {
        ...state,
        layoutColor: action.payload,
      };
    case LayoutActionTypes.CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case LayoutActionTypes.CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case LayoutActionTypes.CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload,
      };
    case LayoutActionTypes.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case LayoutActionTypes.HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case LayoutActionTypes.SAVE_API_RESULT: {
      return {
        ...state,
        apiResult: action.payload.result,
        msg: action.payload.msg,
      };
    }
    default:
      return state;
  }
};

export default Layout;
