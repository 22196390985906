// src/components/InteractiveAssistant.js
import React, { useState } from "react";
import Chat from "./Chat";
import CardTitle from "../../../../components/CardTitle";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Button, Dropdown } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { newConversation } from "../../../../redux/assistantchat/actions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { TrPlan } from "./TrPlan";
import { SuggestionTree } from "./SuggestionTree";
import VerticalStackedNodes from "./VerticalStackedNodes";
import Suggestions from "./Suggestions";
import { HypothesisList } from "./AssistantPanel/HypothesisList";


const AssistantHeaderAndToolbar = ({onChatActive}) => {
  return (<>
    <div className="d-flex align-items-center justify-content-between" style={{ color: '#999999', height: '20px' }}>
      <div style={{
        marginLeft: '15px',
        color: 'rgb(219,225,232)',  // Same as the Left menu.
      }}>
        Assistant
      </div>
      <div className="me-1">
        <i className="mdi mdi-chat-question-outline ms-2" style={{ fontSize: '17px' }} onClick={() => onChatActive(true)}></i>
        <i className="mdi mdi-graph-outline ms-2" style={{ fontSize: '17px' }} onClick={() => onChatActive(false)}></i>
      </div>
    </div>
  </>);
}

const TrPlanToolbar = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between ms-2" style={{ color: '#999999', height: '20px' }}>
        <div className="float-start">
          <label className="form-check-label ms-1">
            Troubleshooting Plan &nbsp; &nbsp;{" "}
          </label>
        </div>
        <Dropdown>
          <Dropdown.Toggle as={Link} to="#" className="arrow-none card-drop">
            <i className="mdi mdi-dots-vertical me-1" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <React.Fragment key={1}>
              <Dropdown.Item>
                <span>
                  <i className="mdi mdi-plus me-1"></i>Re-evaluate Plan
                </span> 
              </Dropdown.Item>
              <Dropdown.Item>
                  <i className="mdi mdi-bookmark-plus-outline me-1"></i>Edit the plan
              </Dropdown.Item>
            </React.Fragment>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <hr className="mt-1 mb-2" />
    </>
  );

}

const ChatToolbar = ({existingConversations, newConversation, changeConversation}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between ms-2" style={{ color: '#999999', height: '20px' }}>
        <div className="float-start">
          <label className="form-check-label ms-1">
            Conversation #ef56a1 &nbsp; &nbsp;{" "}
          </label>
        </div>
        <Dropdown>
          <Dropdown.Toggle as={Link} to="#" className="arrow-none card-drop">
            <i className="mdi mdi-dots-vertical me-1" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <React.Fragment key={1}>
              <Dropdown.Item>
                <span onClick={newConversation}>
                  <i className="mdi mdi-plus me-1"></i> New conversation 
                </span> 
              </Dropdown.Item>
              <Dropdown.Item>
                  <i className="mdi mdi-bookmark-plus-outline me-1"></i>Star this conversation
              </Dropdown.Item>
            </React.Fragment>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <hr className="mt-1 mb-2" />
    </>
  );
}

const NoExistingConversation = () => {
  return (
    <>
      <p> No existing conversation. Create one from above. </p>
    </>
  );
};

export const Assistant = ({ conversation, conversationList }) => {
  const [chatIsActive, setChatIsActive] = useState(true);
  const [convSelection, setConvSelection] = useState([
    conversation || conversationList[0],
  ]);
  const [convList, setConvList] = useState(conversationList || []);
  const dispatch = useDispatch();

  const changeConversationHandler = () => {
    // TODO:
  }

  const newConversationHandler = () => {
    const conversationId = uuid();
    const conversationContext = {
      noContext: true,
      initialTitle: `New conversation - ${conversationId.slice(-6)} `,
    };
    // Dispatch an action, that will
    //    create a new conversation in Backend
    //    create an entry in redux
    //    create a subscription in backend for this conversation.
    dispatch(newConversation(conversationId, conversationContext));
    // Set the state with new conversation id.
    const newConv = {
      id: conversationId,
      value: conversationId,
      label: conversationContext.initialTitle,
    };
    setConvList([...convList, newConv]);
    setConvSelection([newConv]);
    // TODO: Subscribe to changes to this conversationId.
    //       This step is needed to get changes made from other Tabs.
  };

  return (
    <>
      <div>
        <div className="p-1" style={{
          backgroundColor: 'rgb(49,58,70,0.9)',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}>
          <AssistantHeaderAndToolbar onChatActive={setChatIsActive}/>
        </div>
      </div>
      <hr className="bg-dark-lighten m-0 mb-1" />

      {
        chatIsActive ? (<>
          <ChatToolbar 
              existingConversations={convList}
              newConversation={newConversationHandler}
              changeConversation={changeConversationHandler}/>
          <div className="interactive-assistant">
                <Chat conversationId={"123"} />
          </div>
        </>) : (<>
          <TrPlanToolbar/>
          <HypothesisList/>
          {/* <VerticalStackedNodes/> */}
          {/* <SuggestionTree/> */}
          {/* <Suggestions/> */}
          {/* <TrPlan/> */}
        </>)
      }


      {/* <div className="interactive-assistant">
        <Button variant="outline" onClick={() => newConversationHandler()}>
          Start new conversation
        </Button>
        <p className="text-muted font-14 ms-2">
          Choose an existing conversation
        </p>
        <div className="px-2">
          <Typeahead
            id="selectConversation"
            labelKey="label"
            multiple={false}
            onChange={setConvSelection}
            options={conversationList}
            selected={convSelection}
          />
        </div>
        {convSelection.length === 0 ? (
          <NoExistingConversation />
        ) : (
          <Chat conversationId={convSelection[0].conversationId} />
        )}
      </div>
      </div> */}
      <hr className="bg-dark-lighten m-0 mb-1" />
    </>
  );
};
