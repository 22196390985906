import React, { useState } from 'react';
import { Button, Collapse, Row, Col } from 'react-bootstrap';
import { CodeViewer, JsonView, SimpleStrView, TableView } from '../../resultviews/CommonViews';
import LogViewer from '../../resultviews/LogViewer';
import { sampleLogs } from '../../data/LogData';
import { InferredDisplay, TimeseriesChart } from './InferredDisplay';

const ErrorBox = ({errorMessage, open}) => {
  if (!open) {
    return (<></>);
  }
  return (<>
    <div style={{fontSize: '0.8em',}} className='mt-1 ms-1 pb-1'>
      {JSON.stringify(errorMessage)}
    </div>
  </>);
}

const SuccessResultBox = ({currentOp, commandDetails}) => {
  if (!currentOp || !currentOp.opStatus || !currentOp.opStatus.actionResult) {
    return (<></>)
  }

  const dc = currentOp.opStatus.actionResult.dataContainer;
  // let dc = props.lastOpStatus.actionResult.dataContainer;
  switch (dc.typeOfData) {
    case "kCode":
      return (<CodeViewer/>);

    case 'kShellOutput':
      // Use this for finding the type of output -
      // https://chat.openai.com/c/dbbf00f8-1625-481b-83b9-83f252bcc320
      return (<>
        <InferredDisplay commandDetails={commandDetails} op={currentOp} data={dc.stdout}/>
      </>);

    case "kCloudwatchMetrics":
      return (
        <TimeseriesChart data={dc.data} />
      );

    case "kSimpleString":
      return (
        <>
          <Row>
            <Col sm={10}>
              <SimpleStrView strVal={dc.data.val} />
            </Col>
            <Col sm={2} className="d-flex align-items-center">
              {/* <AddVariableCmp handleVarSubmit={handleVarSubmit} /> */}
            </Col>
          </Row>
        </>
      );
    case "kJson":
      return (
        <JsonView jsonStr={dc.data.val} />
      );
    case "kTable":
      return (
        <TableView
          dataTable={dc.dataTable}
          // handleVarSubmit={handleVarSubmit}
        />
      );
    case "kLog":
    case "kLogs":
      return (<LogViewer logs={sampleLogs}/>)
      return <>Not supported</>; // (<LogView text={dc.data.val}/>);
    default:
      return (
        <div className="command-result-details">
          Last run result:
          <br />
          <br />
          Result type {dc.typeOfData} not supported in UI.
        </div>
      );
    }
}


export const CommandStatus = ({ isRunning, currentOp, commandDetails }) => {
  const [open, setOpen] = useState(false);

  let status = 'kProgress';
  status = currentOp?.status || currentOp?.statusCode;

  let error = (status === 'kCompleted') && (currentOp.opStatus?.actionResult.state === 'kError');

  const noShadowButtonStyle = {
    padding: 0,
    margin: 0,
    color: 'grey',
    boxShadow: 'none', // Added to remove the glow
    outline: 'none' // Additionally removes the outline
  };

  if (currentOp) {
    console.log('CommandStatus. currentOp: ' + JSON.stringify(currentOp));
  }

  return (
    <div>
      <div>
        {/* <pre>{JSON.stringify(currentOp, null, 2)}</pre> */}
      </div>
      <div>
        {status === 'kProgress' ? (
          <i>Running</i>
        ) : error ? (
          <div>
            <div style={{backgroundColor: 'rgba(244,232,234, .35)'}}>
              <Button variant="link" onClick={() => setOpen(!open)} style={noShadowButtonStyle}>
                <i className={`mdi mdi-chevron-${open ? 'down' : 'right'}`}></i>
                <span style={{fontSize: '0.85em',}}> Error </span>
              </Button>
            </div>
            <Collapse in={open}>
              <ErrorBox errorMessage={currentOp.opStatus.actionResult.errorMessage} open={open}/>
            </Collapse>
          </div>
        ) : (
          <div>
            <div style={{backgroundColor: 'rgba(232,244,234, .35)'}}>
              <Button variant="link" onClick={() => setOpen(!open)} style={noShadowButtonStyle}>
                <i className={`mdi mdi-chevron-${open ? 'down' : 'right'}`}></i>
                <span style={{fontSize: '0.85em',}}> Success </span>
              </Button>
            </div>
            <Collapse in={open}>
              <div style={{fontSize: '0.85em'}}><SuccessResultBox currentOp={currentOp} commandDetails={commandDetails}/></div>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  );
};
