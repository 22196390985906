
import { Row } from 'react-bootstrap';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export const TimeseriesChart = ({ data }) => {
  const chartData = data.Datapoints.map(point => ({
    Timestamp: new Date(point.Timestamp).toLocaleString(),
    Average: point.Average
  }));

  return (
    // <div>
    <div style={{ width: '100%', minHeight:'200px',  maxHeight: '250px' }}>
      <div className='mt-2 mb-1' style={{display: 'flex', justifyContent: 'center'}}> {data.Label} </div>
      <ResponsiveContainer width="100%" height={250}> {/* aspect ratio width : height */}
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          height={200}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Timestamp" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Average" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>

    </div>
  );
};

const knownCommandsPrefix = {
  kAwsCloudWatch: 'aws cloudwatch',
}

const renderCloudWatchMetrics = (output) => {
  return (<>
    <TimeseriesChart data={JSON.parse(output)}/>
  </>);
}

const knownCommandsRenderFunction = {
  kAwsCloudWatch: renderCloudWatchMetrics
}


const checkForKnownCommands = (commandMainText) => {
  for (let key in knownCommandsPrefix) {
    if (commandMainText.startsWith(knownCommandsPrefix[key])) {
      return key;
    }
  }
  return null;  
}

export const InferredDisplay = ({commandDetails, op, data}) => {

  const commandMainText = commandDetails.mainText;
  const knownCommand = checkForKnownCommands(commandMainText);
  if (knownCommand) {
    let f = knownCommandsRenderFunction[knownCommand];
    return (<div className='mt-3 mb-1'>
      {f(data)}
    </div>);
  }

  return (
        <div>
          <pre> {data} </pre>
        </div>
  );
}

