import { TrsActionTypes } from "./constants";

// varItemsReducer.js
const initialState = {
  varItems: {},
  userAndGroups: [],
  trsApiResult: null,
  msg: ""
};

const VarItems = (state = initialState, action) => {
  switch (action.type) {
    case TrsActionTypes.ADD_VAR_ITEM:
      const v = {};
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => (v[item.name] = item));
      } else {
        v[action.payload.name] = action.payload;
      }
      return {
        ...state,
        varItems: v,
      };
    case TrsActionTypes.GET_USER_GROUPS_SUCCESS: {
      return {
        ...state,
        userAndGroups: action.payload,
      };
    }
    case TrsActionTypes.GET_USER_GROUPS_ERROR: {
      return {
        ...state,
        userAndGroups: [],
      };
    }
    case TrsActionTypes.SAVE_API_RESULT: {
      return {
        ...state,
        trsApiResult: action.payload.result,
        msg: action.payload.msg
      };
    }
    default:
      return state;
  }
};

export default VarItems;
