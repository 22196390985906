// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SettingsActionTypes } from "./constants";
import {
  deleteHost as deleteHostApi,
  getHostList as getHostListApi,
  updateProfile as updateProfileApi,
  createHost as createHostApi,
  updateHost as updateHostApi,
  getAccountList as getAccountListApi,
  deleteAccount as deleteAccountApi,
  updateAccount as updateAccountApi,
  createAccount as createAccountApi,
  getOrgListApi,
  deleteOrgApi,
  createOrgApi,
  inviteMemberApi,
  getOrgOpsApi,
  getUserOpsApi,
  leaveOrgApi,
  getAssistantContextListAPI,
  deleteAssistantContextAPI,
  createAssistantContextAPI,
  updateAssistantContextAPI,
} from "../../helpers/";
import {  
  getAccounts,
  getAccountsApiResponseSuccess,
  getAssistantContextsAction,
  getAssistantContextsApiResponseSuccess,
  getHosts,
  getHostsApiResponseSuccess,
  getOrgsAction,
  getOrgsApiResponseSuccess,
  getOrgsInviteResponseSuccess,
  getOrgsMembersResponseSuccess,
  orgInvitationAction,
  orgMemberAction,
  saveHostApiResponseSuccess,
} from "./actions";
import { apiResultAction } from "../actions";

/**
 * Update Profile
 *
 */
function* updateProfile(action) {
  try {
    yield call(updateProfileApi, action.payload);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the host list
 *
 */
function* getHostList(action) {
  try {
    const response = yield call(getHostListApi, action.payload);
    const data = response.data;
    yield put(getHostsApiResponseSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete Host
 *
 */
function* deleteHost(action) {
  try {
    const response = yield call(deleteHostApi, action.payload);
    yield put(getHosts(action.payload));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to delete host."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Host successfully deleted." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to delete host."}));  
  }
}

/**
 * Create Host
 *
 */
function* createHost(action) {
  try {
    const response = yield call(createHostApi, action.payload);
    const data = response.data;
    yield put(saveHostApiResponseSuccess(data.data));
    yield put(getHosts({ userId: action.payload.ownerUserId }));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to create host."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Host successfully created." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to create host."}));
  }
}

/**
 * Update Host
 *
 */
function* updateHost(action) {
  try {
    const response = yield call(updateHostApi, action.payload);
    const data = response.data;
    yield put(saveHostApiResponseSuccess(data.data));
    yield put(getHosts({ userId: action.payload.ownerUserId }));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to update host."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Host successfully updated." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to update host."}));  
  }
}

/**
 * Get the account list
 *
 */
function* getAccountList(action) {
  try {
    const response = yield call(getAccountListApi, action.payload);
    const data = response.data;
    yield put(getAccountsApiResponseSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete Host
 *
 */
function* deleteAccount(action) {
  try {
    const response = yield call(deleteAccountApi, action.payload);
    yield put(getAccounts(action.payload));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to delete account."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Account successfully deleted." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to delete account."}));  
  }
}

/**
 * Create account
 *
 */
function* createAccount(action) {
  try {
    const response = yield call(createAccountApi, action.payload);
    yield put(getAccounts({ userId: action.payload.ownerId }));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to create account."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Account successfully created." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to create account."}));  
  }
}

/**
 * Update account
 *
 */
function* updateAccount(action) {
  try {
    const response = yield call(updateAccountApi, action.payload);
    yield put(getAccounts({ userId: action.payload.ownerId }));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to update account."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Account successfully updated." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to update account."}));
  }
}

/**
 * Get the org list
 *
 */
function* getOrgList(action) {
  try {
    const response = yield call(getOrgListApi, action.payload);
    const data = response.data;
    yield put(getOrgsApiResponseSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete Org
 *
 */
function* deleteOrg(action) {
  try {
    const response = yield call(deleteOrgApi, action.payload);
    yield put(getOrgsAction(action.payload));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to delete organization."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Organization successfully deleted." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to delete organization."})); 
  }
}

/**
 * Create Org
 *
 */
function* createOrg(action) {
  try {
    const response = yield call(createOrgApi, action.payload);
    yield put(getOrgsAction({ userId: action.payload.ownerUserId }));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to create organization."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Organization successfully created." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to create organization."})); 
  }
}

/**
 * Invite member
 *
 */
function* inviteMember(action) {
  try {
    yield call(inviteMemberApi, action.payload);
    yield put(getOrgsAction({ userId: action.payload.ownerUserId }));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get Org member
 *
 */
function* getOrgMember(action) {
  try {
    const response = yield call(getOrgOpsApi, action.payload);
    const data = response.data;
    yield put(getOrgsMembersResponseSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get Org Invites
 *
 */
function* getOrgInvites(action) {
  try {
    const response = yield call(getUserOpsApi, action.payload);
    const data = response.data;
    yield put(getOrgsInviteResponseSuccess(data.data?.invitations));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Remove Org Member
 *
 */
function* removeOrgMember(action) {
  try {
    yield call(getOrgOpsApi, action.payload);
    yield put(orgMemberAction({ ...action.payload, op: "getOrgUsers" }));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Accept Org Invite
 *
 */
function* acceptOrgInvite(action) {
  try {
    yield call(getOrgOpsApi, action.payload);
    yield put(orgInvitationAction({ ...action.payload, op: "getOrgInvites" }));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Leave Org
 *
 */
function* leaveOrg(action) {
  try {
    yield call(leaveOrgApi, action.payload);
    yield put(getOrgsAction(action.payload));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the assistant context list
 *
 */
function* getAssistantContextList(action) {
  try {
    const response = yield call(getAssistantContextListAPI, action.payload);
    const data = response.data;
    yield put(getAssistantContextsApiResponseSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete Assistant Context
 *
 */
function* deleteAssistantContext(action) {
  try {
    const response = yield call(deleteAssistantContextAPI, action.payload);
    yield put(getAssistantContextsAction(action.payload));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to delete Assistant context."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Assistant context successfully deleted." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "success", msg: "Assistant context successfully deleted." }));
  }
}

/**
 * Create Assistant Context
 *
 */
function* createAssistantContext(action) {
  try {
    const userId = action?.payload?.get("ownerUserId");
    const response = yield call(createAssistantContextAPI, action.payload);
    yield put(getAssistantContextsAction({ userId }));    
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to create Assistant context."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Assistant context successfully created." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to create Assistant context." }));
  }
}

/**
 * Update Assistant Context
 *
 */
function* updateAssistantContext(action) {
  try {
    const userId = action?.payload?.get("ownerUserId");
    const response = yield call(updateAssistantContextAPI, action.payload);
    yield put(getAssistantContextsAction({ userId }));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultAction({ result: "error", msg: "Failed to update Assistant context."}));  
    } else {
      yield put(apiResultAction({ result: "success", msg: "Assistant context successfully updated." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultAction({ result: "error", msg: "Failed to update Assistant context."}));  
  }
}

export function* watchUpdateProfile() {
  yield takeEvery(SettingsActionTypes.UPDATE_PROFILE, updateProfile);
}

export function* watchGetHostList() {
  yield takeEvery(SettingsActionTypes.GET_HOSTS, getHostList);
}

export function* watchDeleteHost() {
  yield takeEvery(SettingsActionTypes.DELETE_HOST, deleteHost);
}

export function* watchCreateHost() {
  yield takeEvery(SettingsActionTypes.SAVE_HOST, createHost);
}

export function* watchUpdateHost() {
  yield takeEvery(SettingsActionTypes.UPDATE_HOST, updateHost);
}

export function* watchGetAccountList() {
  yield takeEvery(SettingsActionTypes.GET_ACCOUNTS, getAccountList);
}

export function* watchDeleteAccount() {
  yield takeEvery(SettingsActionTypes.DELETE_ACCOUNT, deleteAccount);
}

export function* watchCreateAccount() {
  yield takeEvery(SettingsActionTypes.SAVE_ACCOUNT, createAccount);
}

export function* watchUpdateAccount() {
  yield takeEvery(SettingsActionTypes.UPDATE_ACCOUNT, updateAccount);
}

export function* watchGetOrgList() {
  yield takeEvery(SettingsActionTypes.GET_ORGS, getOrgList);
}

export function* watchDeleteOrg() {
  yield takeEvery(SettingsActionTypes.DELETE_ORG, deleteOrg);
}

export function* watchCreateOrg() {
  yield takeEvery(SettingsActionTypes.SAVE_ORG, createOrg);
}

export function* watchInviteMember() {
  yield takeEvery(SettingsActionTypes.INVITE_MEMBER, inviteMember);
}

export function* watchGetOrgMember() {
  yield takeEvery(SettingsActionTypes.GET_ORG_MEMBER, getOrgMember);
}

export function* watchGetOrgInvites() {
  yield takeEvery(SettingsActionTypes.GET_ORG_INVITES, getOrgInvites);
}

export function* watchRemoveOrgMember() {
  yield takeEvery(SettingsActionTypes.REMOVE_ORG_MEMBER, removeOrgMember);
}

export function* watchAcceptOrgInvite() {
  yield takeEvery(SettingsActionTypes.ACCEPT_ORG_INVITATION, acceptOrgInvite);
}

export function* watchLeaveOrg() {
  yield takeEvery(SettingsActionTypes.LEAVE_ORG, leaveOrg);
}

export function* watchGetAssistantContexts() {
  yield takeEvery(
    SettingsActionTypes.GET_ASSISTANT_CONTEXTS,
    getAssistantContextList
  );
}

export function* watchDeleteAssistantContext() {
  yield takeEvery(
    SettingsActionTypes.DELETE_ASSISTANT_CONTEXT,
    deleteAssistantContext
  );
}

export function* watchCreateAssistantContext() {
  yield takeEvery(
    SettingsActionTypes.SAVE_ASSISTANT_CONTEXT,
    createAssistantContext
  );
}

export function* watchUpdateAssistantContext() {
  yield takeEvery(
    SettingsActionTypes.UPDATE_ASSISTANT_CONTEXT,
    updateAssistantContext
  );
}

function* settingsSaga() {
  yield all([
    fork(watchUpdateProfile),
    fork(watchGetHostList),
    fork(watchDeleteHost),
    fork(watchCreateHost),
    fork(watchUpdateHost),
    fork(watchGetAccountList),
    fork(watchDeleteAccount),
    fork(watchCreateAccount),
    fork(watchUpdateAccount),
    fork(watchGetOrgList),
    fork(watchDeleteOrg),
    fork(watchCreateOrg),
    fork(watchInviteMember),
    fork(watchGetOrgMember),
    fork(watchGetOrgInvites),
    fork(watchRemoveOrgMember),
    fork(watchAcceptOrgInvite),
    fork(watchLeaveOrg),
    fork(watchGetAssistantContexts),
    fork(watchDeleteAssistantContext),
    fork(watchCreateAssistantContext),
    fork(watchUpdateAssistantContext),
  ]);
}

export default settingsSaga;
