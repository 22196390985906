export const ResourceActionTypes = {  
  GET_RESOURCES: "GET_RESOURCES",
  GET_RESOURCES_API_SUCCESS: "GET_RESOURCES_API_SUCCESS",  
  SAVE_RESOURCES: "SAVE_RESOURCES",
  SAVE_RESOURCES_API_SUCCESS: "SAVE_RESOURCES_API_SUCCESS",
  GET_RESOURCE_BY_ID_API_SUCCESS: "GET_RESOURCE_BY_ID_API_SUCCESS",
  GET_RESOURCE_BY_ID: "GET_RESOURCE_BY_ID",
  DELETE_RESOURCE: "DELETE_RESOURCE",
  UPDATE_RESOURCE: "UPDATE_RESOURCE",
  GET_JIRA_PROJECTS : 'GET_JIRA_PROJECTS',
  GET_JIRA_ISSUES : 'GET_JIRA_ISSUES',
  GET_JIRA_PROJECTS_SUCCESS : "GET_JIRA_PROJECTS_SUCCESS",
  GET_JIRA_ISSUES_SUCCESS : "GET_JIRA_ISSUES_SUCCESS",
};
