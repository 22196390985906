import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import { useDispatch, useSelector } from "react-redux";
import { apiResultTrsShell, createPlaybookAction } from "../../../../redux/actions";

const nodeTypes = {
  custom: CustomNode,
};

const initialNodes = [
  {
    id: "1",
    className: "annotation",
    data: { label: "Check ingestion volume for customer" },
    position: {
      x: 100,
      y: 0,
    },
  },
];

const initialEdges = [
  {
    type: "smoothstep",
    source: "1",
    target: "2",
    id: "1",
    label: "",
  },
];

const CreateWorkspaceModal = (props) => {
  const { onCancel, showModal, selectedCmd, nodes: cmdNodes } = props;
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [nodeDesc, setNodeDesc] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {   
    const json = selectedCmd.map((cmd) => {
      const item = cmdNodes.find((node) => node.id === cmd) || {};
      return {
        id: item.id,
        command: item?.commandDetails?.mainText,
        desciption: nodeDesc[item.id],
      };
    });
    const payload = {
      title: text,
      ownerUserId: user.userId,
      json: {
        sequenceSpecificData: json
      },
    };
    await dispatch(createPlaybookAction(payload));
    onCancel();
  };

  const flowchartStyle = {
    height: "500px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    if (showModal && selectedCmd.length > 0 && cmdNodes.length > 0) {
      const newNodes = _.cloneDeep(nodes);
      const newEdges = _.cloneDeep(edges);
      for (let i = 0; i < selectedCmd.length; i++) {
        const index = cmdNodes.findIndex((item) => item.id === selectedCmd[i]);
        newEdges.push({
          type: "smoothstep",
          source: newNodes[0].id,
          target: selectedCmd[i],
          id: selectedCmd[i],
          label: "",
        });
        newNodes.push({
          id: selectedCmd[i],
          type: "custom",
          position: {
            x: 100,
            y: newNodes[newNodes.length - 1].position.y + (i > 0 ? 125 : 70),
          },
          data: {
            ...cmdNodes[index],
            nodeDesc,
            setNodeDesc,
          },
        });
      }
      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [showModal, selectedCmd, cmdNodes]);

  useEffect(() => {
    dispatch(apiResultTrsShell({result: null, msg: null}));
  }, []);

  return (
    <Modal show={showModal} onHide={onCancel} size="xl" centered>
      <Modal.Header
        className="share-modal-header"
        closeButton
        onHide={onCancel}
      >
        <Modal.Title>Create Playbook</Modal.Title>
      </Modal.Header>
      <div className="p-1">
        <Modal.Body className="px-3 pt-2 pb-0">
          <Form.Group as={Col} controlId="formGridState" className="mb-3">
            <div className="col-12">
              <div className="d-flex align-items-center w-auto mb-3">
                <div className="me-2" style={{ width: 160 }}>
                  Customer Name:
                </div>
                <Form.Control
                  aria-label="Customer"
                  style={{ width: 250 }}
                  onChange={handleTextChange}
                  value={text}
                />
              </div>
            </div>
          </Form.Group>
          <div className="py-2" style={flowchartStyle}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
            >
              <Background />
            </ReactFlow>
          </div>
          <div className="py-2">
            <Button
              variant="primary"
              type="submit"
              className="me-1"
              onClick={handleSubmit}
              disabled={text?.length === 0}
            >
              Create
            </Button>
            <Button variant="light" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default CreateWorkspaceModal;
