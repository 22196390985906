import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { ChatActionTypes } from './constants';
import { addMessage } from './actions';
import { initWebSocket, sendMessage, sendNewConversationMessage } from '../../utils/websocket';

function* handleWebSocketConnect() {
  yield call(initWebSocket, function* (message) {
    yield put(addMessage(message));
  });
}

function* watchNewConversationMessage() {
  yield takeEvery(ChatActionTypes.NEW_CONVERSATION, function* (action) {
    yield call(sendNewConversationMessage, action.payload);
  });
}

function* watchSendMessage() {
  yield takeEvery(ChatActionTypes.SEND_MESSAGE, function* (action) {
    yield call(sendMessage, action.payload);
  });
}

function* assistantChatSaga() {
  yield all([
    fork(handleWebSocketConnect),
    fork(watchNewConversationMessage),
    fork(watchSendMessage),
  ]);
}

export default assistantChatSaga;