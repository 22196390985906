import { call, put, takeEvery, all, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import * as actionTypes from './constants';
import { updateSdocInStore, updateOpStatus } from './actions';

let updateInProgress = {}; // Store update in progress state for each sdoc
let lastFetchedVersion = {};  // docId --> lastFetchedVersion.
let opsBeingPolled = {};


// Take the sdoc content present in Redux and send it to the backend to update.
// Backend will resolve any conflicts before saving it.
// If there were conflicts, the new sdoc will be fetched as part of fetchAndUpdateSdoc.
function* updateSdocSaga(action) {
  try {
    // Get the updated sdoc from the Redux store
    const sdoc = yield select(state => {
      return state.ctx.sdocs.find(s => s.docId === action.payload.docId)
      }
    );

    if (!sdoc) {
      // Handle case where sdoc is not found in the store
      console.error('sdoc not found in the store');
      return;
    }

    updateInProgress[sdoc.docId] = true; // Set update in progress
    const response = yield call(axios.put, `/sdocsctx/${sdoc.docId}`, { ...sdoc, lastFetchedVersion: sdoc.version });
    if (response.status === 200) {
      const newSdoc = response.data;
      if (newSdoc.version !== sdoc.version) {
        lastFetchedVersion[sdoc.docI] = newSdoc.version;
        yield put(updateSdocInStore(newSdoc));
      }
    }
    updateInProgress[sdoc.docId] = false; // Reset update in progress

  } catch (error) {
    // Dispatch an error action or handle the error as needed
    console.error('Error updating sdoc:', error);
    updateInProgress[action.payload.docId] = false; // Reset update in progress on error
  }
}

function* fetchSDocSaga(action) {
  try {
    const response = yield call(axios.get, `/sdocsctx/${action.payload.docId}`);
    lastFetchedVersion[action.payload.docId] = response.data.version;
    yield put(updateSdocInStore(response.data));
  } catch (error) {
    console.error('Error fetching sdoc:', error);
  }
}

// Called every N seconds to fetch the sdoc from the backend.
// If there are no concurrent updates being made to the sdoc, then the backend will
// always return the latest sdoc that was just written by updateSdocSaga - in this
// case, the redux store will not be updated.
// If concurrent updates are being made to the same sdoc, then backend will return a
// new version of the sdoc that should be saved to redux.
function* fetchAndUpdateSdoc(docId) {
  if (updateInProgress[docId]) {
    return; // Skip fetch if an update is in progress
  }

  try {
    const response = yield call(axios.get, `/sdocsctx/${docId}`);
    const fetchedSdoc = response.data;

    const storedSdoc = yield select(state => state.ctx.sdocs.find(s => s.docId === docId));
    if (!storedSdoc || storedSdoc.version !== fetchedSdoc.version) {
      lastFetchedVersion[docId] = fetchedSdoc.version;
      yield put(updateSdocInStore(fetchedSdoc));
    }
  } catch (error) {
    console.error('Error fetching sdoc:', error);
  }
}

function* pollSdocsSaga() {
  while (true) {
    const sdocsInStore = yield select(state => state.ctx.sdocs);
    for (const sdoc of sdocsInStore) {
      yield fetchAndUpdateSdoc(sdoc.docId);
    }
    yield delay(10000); // Poll every 10 seconds, adjust as needed
  }
}

function* pollOpStatus(action) {
  // NOTE: This poll can be optimized such that if opStatus is already in kCompleted
  // state, then do not poll again for the status.
  const opId = action.payload.opId;
  try {
    // Check if operation already exists in Redux store
    // const existingOp = yield select(state => state.ctx.ops.find(op => op.opId === opId));
    
    // Check if polling for this op has already started (or has completed).
    if (opsBeingPolled[opId]) {
      console.log('Preventing duplicate poll for : ' + opId);
      return;
    }
    opsBeingPolled[opId] = true;  // This remains true even if polling is complete.

    // Start polling.
    while (true) {
      const response = yield call(axios.get, `/ops/${opId}`);
      yield put(updateOpStatus(opId, response.data));
      if (response.data.statusCode === 'kCompleted' || response.data.statusCode === 'kInvalidOpId') {
        break;
      }
      yield delay(2000);
    }
  } catch (error) {
    console.error('Error polling op status:', error);
  }
}



export default function* ctxSaga() {
  yield all([
    takeEvery([
      actionTypes.UPDATE_SDOC_ADD_NODE,
      actionTypes.UPDATE_SDOC_DELETE_NODE,
      actionTypes.UPDATE_SDOC_UPDATE_NODE,
      actionTypes.UPDATE_SDOC_ADD_VARS,
      actionTypes.UPDATE_SDOC_UPDATE_VARS
    ], updateSdocSaga),
    takeEvery(actionTypes.FETCH_SDOC, fetchSDocSaga),
    pollSdocsSaga(),
    takeEvery(actionTypes.FETCH_OP_STATUS, pollOpStatus)
  ]);
}
