import React from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormInput } from "../../../components";
import { useEffect } from "react";
import LineChart from "./LineChart";
import BarChart from "./BarChart";

const ChartView = (props) => {
  const { data } = props;
  const [type, setType] = useState("line");
  const [xaxis, setXaxis] = useState(null);
  const [yaxis, setYaxis] = useState(null);
  const [xaxisData, setXaxisData] = useState([]);
  const [yaxisData, setYaxisData] = useState([]);

  useEffect(() => {
    if (data?.colName?.length > 0) {
      let xLabels = [],
        yLabel = [];
      for (let i = 0; i < data.colTypes.length; i++) {
        const colType = data.colTypes[i];
        const colName = data.colName[i];
        if (colType === "kDateString" || colType === "kInt64") {
          xLabels.push(colName);
        }

        if (colType === "kInt64") {
          yLabel.push(colName);
        }
      }
      setXaxisData(xLabels);
      setYaxisData(yLabel);
      xLabels.length > 0 && setXaxis(xLabels[0]);
      yLabel.length > 0 && setYaxis(yLabel[0]);
    }
  }, [data]);

  return (
    <div className="">
      <Row className="mb-3">
        <Col className="col-3">
          <FormInput
            label="Chart Type"
            name="type"
            containerClass={"col-sm-12"}
            type="select"
            onChange={({ target }) => setType(target.value)}
            value={type}
            size={"sm"}
          >
            <option value="bar">Bar Chart</option>
            <option value="line">Line Chart</option>
          </FormInput>
        </Col>
        <Col className="col-4">
          <FormInput
            label="X-axis"
            name="x-axis"
            type="select"
            onChange={({ target }) => setXaxis(target.value)}
            value={xaxis}
            size={"sm"}
          >
            {xaxisData.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </FormInput>
        </Col>
        <Col className="col-4">
          <FormInput
            label="Y-axis"
            name="y-axis"
            type="select"
            onChange={({ target }) => setYaxis(target.value)}
            value={yaxis}
            size={"sm"}
          >
            {yaxisData.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </FormInput>
        </Col>
        {/* <Col className="col-3 d-flex align-items-end">
          <div>
            <Button
              variant="light"
              size={"sm"}
              disabled={!type || !xaxis || !yaxis}
            >
              Apply
            </Button>
          </div>
        </Col> */}
      </Row>
      <Row className="mb-2">
        {xaxis && yaxis && (
          <Col className="col-12">
            {type === "line" && (
              <LineChart data={data} xaxis={xaxis} yaxis={yaxis} />
            )}
             {type === "bar" && (
              <BarChart data={data} xaxis={xaxis} yaxis={yaxis} />
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ChartView;
