import { SettingsActionTypes } from "./constants";

// SettingsReducer.js
const initialState = {
  varItems: {},
  userAndGroups: [],
  hostList: [],
  hostDetails: {},
  accountList: [],
  accountDetails: {},
  orgList: [],
  orgDetails: {},
  orgMemberList: [],
  orgInviteList: [],
  assistantContextList: [],
  assistantContextDetails: {},
  assistantContextApiResult: null,
  msg: null
};

const SettingsItems = (state = initialState, action) => {
  switch (action.type) {
    case SettingsActionTypes.GET_HOSTS_API_SUCCESS: {
      return {
        ...state,
        hostList: action.payload,
      };
    }
    case SettingsActionTypes.SAVE_HOST_API_SUCCESS: {
      return {
        ...state,
        hostDetails: action.payload,
      };
    }
    case SettingsActionTypes.GET_ACCOUNTS_API_SUCCESS: {
      return {
        ...state,
        accountList: action.payload,
      };
    }
    case SettingsActionTypes.GET_ORGS_API_SUCCESS: {
      return {
        ...state,
        orgList: action.payload,
      };
    }
    case SettingsActionTypes.GET_ORG_MEMBER_API_SUCCESS: {
      return {
        ...state,
        orgMemberList: action.payload,
      };
    }
    case SettingsActionTypes.GET_ORG_INVITES_API_SUCCESS: {
      return {
        ...state,
        orgInviteList: action.payload,
      };
    }
    case SettingsActionTypes.GET_ASSISTANT_CONTEXTS_API_SUCCESS: {
      return {
        ...state,
        assistantContextList: action.payload,
      };
    }    
    default:
      return state;
  }
};

export default SettingsItems;
