import { uuid, uuid as uuidv4 } from 'uuidv4';
import * as actionTypes from './constants';

const initialState = {
  sdocs: [],
  ops: [],
  currentWsDoc: null,
};

function updateNodeInSdoc(sdocs, docId, updatedNode) {
  return sdocs.map(sdoc => {
    if (sdoc.docId !== docId) return sdoc;
    return {
      ...sdoc,
      version: uuidv4(),
      nodes: sdoc.nodes.map(node => node.id === updatedNode.id ? updatedNode : node)
    };
  });
}

function opsReducer(state = initialState.ops, action) {
  switch (action.type) {
    case 'ADD_OP':  // Needs to be Idempotent. Don't add duplicate ops.
      // Rationale for making it idempotent -
      //    React components are re-rendered often.
      //    An Op added from another browser session is learnt by polling the sdoc.
      //    Such an Op is added when loading the new sdoc. This can cause the components
      //    to re-add the same Op in some scenarios. Hence idempotency is required.
      //    It removes a lot of constraints on the component - TrShellMain or CommandNodeMain to
      //    add the Op exactly once across re-renders.
      // Check if op is already added. Don't added it again.
      const opExists = state.find(op => op.opId === action.payload.op.opId);
      if (opExists) {
        // If operation already exists, return the current state
        return state;
      }
      // Add the new Op.
      return [...state, action.payload.op];
    case 'UPDATE_OP_STATUS':
      const responseData = action.payload.responseData;
      const opId = responseData.opId;
      const statusCode = responseData.statusCode;
      const opStatus = responseData;
      return state.map(op => op.opId === action.payload.opId ? { ...op, status: statusCode, opStatus: opStatus } : op);
    default:
      return state;
  }
}

function wsDocReducer(state = initialState.currentWsDoc, action) {
  console.log('wsDocReducer');
  switch (action.type) {
    case actionTypes.UPDATE_WSDOC:
      return action.payload;

    default:
      return state;
  }
  return state;
}

function sdocsReducer(state = initialState.sdocs, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SDOC_IN_STORE:  // This also ADDS if not present.
      const sdocExists = state.some(sdoc => sdoc.docId === action.payload.docId);
      const newState = sdocExists
          ? state.map(sdoc => 
                sdoc.docId === action.payload.docId ? action.payload : sdoc)
              : [...state, action.payload];
      return newState;

    case actionTypes.UPDATE_SDOC_ADD_NODE:
      return state.map(sdoc => 
        sdoc.docId === action.payload.docId ? 
          { ...sdoc, version: uuidv4(), nodes: [...sdoc.nodes, action.payload.node] } : 
          sdoc
      );

    case actionTypes.UPDATE_SDOC_DELETE_NODE:
      return state.map(sdoc => 
          sdoc.docId === action.payload.docId ? 
            { ...sdoc, version: uuidv4(), nodes: sdoc.nodes.filter(node => node.id !== action.payload.nodeId) } : 
            sdoc
        );

    case actionTypes.UPDATE_SDOC_UPDATE_NODE:
      return updateNodeInSdoc(state, action.payload.docId, action.payload.node);

    case actionTypes.UPDATE_SDOC_ADD_VARS:
    case actionTypes.UPDATE_SDOC_UPDATE_VARS:
      return {
        ...state,
        sdocs: state.sdocs.map(sdoc => 
          sdoc.docId === action.payload.docId ? 
            { ...sdoc, vars: { ...sdoc.vars, ...action.payload.vars } } : 
            sdoc
        )
      };

    default:
      return state;
  }
}

export default function ctxReducer(state = initialState, action) {
  return {
    sdocs: sdocsReducer(state.sdocs, action),
    ops: opsReducer(state.ops, action),
    wsdoc: wsDocReducer(state.currentWsDoc, action),
  };
}