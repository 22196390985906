import { uuid } from "uuidv4";
import { ResourceTypesEnum } from "../data/constants";

export const getDefaultHostDetails = () => {
  return {
    hostname: 'rohit-mac',
    hostId: 'f2b3b9ac-1bdf-4ea9-9ace-da502d3913de',
  }
}

export const constructEmptyCommandNode = () => {
  return {
    id: uuid(),
    type: 'kCommand',
    resourceId: null,
    commandDetails: {
      mainText: '',
    }
  }
}

export const constructCommandNode = (
  resourceId,
  mainText,
  extraText,
  additionalFieldsMap,
) => {
  return {
    id: uuid(),
    resourceId,
    type: 'kCommand',
    commandDetails: {
      mainText,
      extraText,
      additionalFieldsMap,
      resourceId
    }
  };
}

export const iconClassFromResourceType = (resourceType) => {
  switch (resourceType) {
    case ResourceTypesEnum.kAwsAuroraDB:
    case ResourceTypesEnum.kPostgresDB:
      return 'mdi mdi-database';
    case ResourceTypesEnum.kShellCommand:
      return 'mdi mdi-console';
    case ResourceTypesEnum.kElasticLogs:
      return 'mdi mdi-file-table-box-outline';
    default:
      return 'mdi mdi-console-line';
  }
}


export const formatCommand = (command) => {
  // Remove extra whitespaces
  command = command.replace(/\s+/g, ' ');

  // Format SQL queries
  command = command.replace(/(\bSELECT\b|\bFROM\b|\bWHERE\b|\bAND\b|\bOR\b|\bORDER BY\b|\bGROUP BY\b|\bLIMIT\b|\bJOIN\b|\bLEFT JOIN\b|\bRIGHT JOIN\b|\bINNER JOIN\b|\bOUTER JOIN\b|\bON\b)/gi, '\n$1');

  return command;
}