import { ResourceActionTypes } from "./constants";

// ResourceReducer.js
const initialState = {
  resourceList: [],
  resourceDetails: {},
  jiraProjects: [],
  JiraIssues: []
};

const ResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ResourceActionTypes.GET_RESOURCES_API_SUCCESS: {
      return {
        ...state,
        resourceList: action.payload,
      };
    }
    case ResourceActionTypes.SAVE_RESOURCES_API_SUCCESS: {
      return {
        ...state,
        resourceDetails: action.payload,
      };
    }
    case ResourceActionTypes.GET_RESOURCE_BY_ID_API_SUCCESS: {
      return {
        ...state,
        resourceDetails: action.payload,
      };
    }
    case ResourceActionTypes.GET_JIRA_ISSUES_SUCCESS: 
    return {
      ...state,
      jiraIssues: action.payload,
    };   
    case ResourceActionTypes.GET_JIRA_PROJECTS_SUCCESS: 
    return {
      ...state,
      jiraProjects: action.payload,
    }; 
    default:
      return state;
  }
};

export default ResourceReducer;
