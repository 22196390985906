import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Row, Col, Button, Dropdown, Spinner } from 'react-bootstrap';
import { CommandStatus } from './CommandStatus';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import { addOp, fetchOpStatus, updateSdocUpdateNode } from '../../../../redux/actions';
import _, { debounce } from 'lodash';
import { CmdMainTextBix } from './CmdMainTextBox';
import { ResourceTypesEnum, resourceNames } from '../../data/constants';
import { formatCommand, getDefaultHostDetails, iconClassFromResourceType } from '../utils';
import { ConstructionOutlined } from '@mui/icons-material';
import { CmdBottomToolbar } from './CommandNode/CmdBottomToolbar';
import LogLogo from "../../../../assets/images/log-icon.png";


// Custom selector function
const selectOpById = (state, opId) => state.ctx.ops.find(op => op.opId === opId);

const CommandNodeMain = ({ commandDetails, nodeInfo, onCommandChange, onDelete, updateSdocWithOp, defaultHostResource, setSelectedCmd, selectedCmd }) => {
  const miniStatusMessages = {
    'startingMessage': 'Type "resource=" to choose a resource on which a command should be run...',
    'postResourceSelection': 'Enter a valid command to run...',
    'runnable': 'Hit run to submit the command...',
    'regularMode': '',
  }
  const [opId, setOpId] = useState(nodeInfo.opDetails ? nodeInfo.opDetails.opId : null); // State to track the current operation ID
  const [mode, setMode] = useState(opId ? 'regular' : 'initial'); // Manage display modes
  // State for miniStatusInitial
  const [miniStatusInitial, setMiniStatusInitial] = useState(miniStatusMessages.startingMessage);

  const dispatch = useDispatch();

  // currentOp is basically the completed Op, if any.
  // const currentOp = useSelector(state => selectOpById(state, opId), (oldOp, newOp) => (newOp.status === 'kCompleted'));
  const currentOp = useSelector(state => selectOpById(state, opId));

  // Add state to manage toolbar visibility
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  // Handlers to toggle toolbar visibility
  const handleMouseEnter = () => setIsToolbarVisible(true);
  const handleMouseLeave = () => setIsToolbarVisible(false);
  const handleFocus = () => setIsToolbarVisible(true);
  const handleBlur = () => setIsToolbarVisible(false);

  const waitingForResult = opId ? (currentOp?.status === 'kCompleted' ? false : true) : false;
  let completed = false;

  console.log('-----------------');
  console.log(`Rendering CommandNodeMain with values: opId: ${opId}, waitingForResult: ${waitingForResult}`);
  console.dir(currentOp);
  console.log('-----------------');


  if (opId && !currentOp) {
    dispatch(fetchOpStatus(opId));
    // If op is completed then
    if (currentOp) {
      completed = true;
    }
  }

  const handleCommandChange = (which, text) => {
    nodeInfo.commandDetails.mainText = text;
    // console.log(text);
    updateMiniStatus(text);  // Update the mini status based on the text
  }

  const updateMiniStatus = (text) => {
    if (text.trim() === '') {
      setMiniStatusInitial(miniStatusMessages.startingMessage);
    } else {
      // if (text.trim().startsWith('resource=') && text.trim().indexOf(' ') === -1
      // Check if the text contains the resource format
      const resourcePattern = /\[(.*?)\]\((.*?)\)/;
      const resourceMatch = text.match(resourcePattern);

      if (resourceMatch && resourceMatch.length >= 3) {
        const resourceName = resourceMatch[1];
        const resourceId = resourceMatch[2];
        console.log('Resource name: |' + resourceName + '|');
        console.log('Resource ID: |' + resourceId + '|');

        // Check if the resource ID exists in the resourceNames
        if (resourceId && resourceNames.some(resource => resource.resourceId === resourceId)) {
          if (text.trim().endsWith(')')) {
            setMiniStatusInitial(miniStatusMessages.postResourceSelection);
          } else {
            setMiniStatusInitial(miniStatusMessages.runnable);
          }
        }
      } else {  // Case where the text does not begin with "resource="
        // Handle the case for ". " -- called the DotPrevious case.
        // DotPrevious means, the command box will use the resource from the last command box.
        // TODO: Use sdoc to figure out the last resource that was used.
        if (text.trim() === '. ') {
          console.log('Encountered DotPrevious operator.');
          nodeInfo.commandDetails.mainText = 'resource=' + defaultHostResource.hostId;
          // We call this function again with new value so that it will process and set state.
          updateMiniStatus(nodeInfo.commandDetails.mainText);
          return;
          // NOTE: This logic is not working because we need to set the CmdBox with the new text as well.
          //       We may need to use a ref.
        }

        // Handle case for default resource.
        // User should have entered a alphanumeric character, and should have set the default resource
        // for this case to work.
        if (defaultHostResource) {
          if (text.trim().length > 1) {
            setMiniStatusInitial(miniStatusMessages.runnable);
          } else {
            setMiniStatusInitial(miniStatusMessages.postResourceSelection);
          }
        } else {
          setMiniStatusInitial(miniStatusMessages.startingMessage);
        }
      }
    }
  }

  const parseCommandBoxes = (mainTextWithMentions) => {
    let commandWithoutResource = '';

    const resourcePattern = /\[(.*?)\]\((.*?)\)/;
    const resourceMatch = mainTextWithMentions.match(resourcePattern);

    // Check if the resource ID exists in the resourceNames
    if (resourceMatch && resourceMatch.length >= 3) {
      const resourceName = resourceMatch[1];
      const resourceId = resourceMatch[2];
      console.log('Resource name: |' + resourceName + '|');
      console.log('Resource ID: |' + resourceId + '|');

      if (resourceId && resourceNames.some(resource => resource.resourceId === resourceId)) {
        // Find the end index of the resource mention
        const endOfResourceIndex = mainTextWithMentions.indexOf(resourceMatch[0]) + resourceMatch[0].length;

        // Extract everything after the resource mention
        commandWithoutResource = mainTextWithMentions.substring(endOfResourceIndex).trim();

        if (mainTextWithMentions.trim().endsWith(')')) {
          return {
            resourceId,
          }
        } else {
          // TODO: Get the remaining command. Set it in commandWithoutResource.
          return {
            resourceId,
            runnableMainCommand: commandWithoutResource,
          }
        }
      }
    }

    if (defaultHostResource) {
      return {
        resourceId: defaultHostResource.hostId,
        runnableMainCommand: mainTextWithMentions.trim(),
      }
    }

    // Assume command will be run on the default resource
    return null;
  }

  const parseCommand = () => {
    const parsedDetails = parseCommandBoxes(nodeInfo.commandDetails.mainText);
    if (!parsedDetails) {
      console.log('Parsing failed. Cannot run the command');
      return;
    }

    if (!parsedDetails.runnableMainCommand) {
      console.log('Not a runnable command');
    }

    const runnableMainCommand = formatCommand(parsedDetails.runnableMainCommand);

    // Return command details with resource info.
    return {
      mainText: runnableMainCommand,
      extraText: '',
      additionalFieldsMap: {},
      resourceId: parsedDetails.resourceId,
      resourceName: resourceNames.find(r => r.resourceId == parsedDetails.resourceId).resourceName,
      resourceType: resourceNames.find(r => r.resourceId == parsedDetails.resourceId).resourceType,
    }
  }

  const handleRun = () => {
    const commandDetailsWithResource = parseCommand();
    if (!commandDetailsWithResource) {
      console.log('Trying to run without a valid command.');
      return;
    }
    console.log('Running the below parsed command: ');
    console.dir(commandDetailsWithResource);
    // Update nodeInfo with the new command details.
    nodeInfo.commandDetails = commandDetailsWithResource;
    // Logic to execute command
    const newOpId = uuidv4(); // Generate a UUID for the opId
    setOpId(newOpId); // Update the opId state
    submitCommand(commandDetailsWithResource, newOpId); // Call the function with opId, not waiting for its return
    dispatch(addOp(newOpId, {
      opId: newOpId,
      resourceId: commandDetailsWithResource.resourceId, //'demo-resource-id',
      commandDetails: commandDetailsWithResource, // nodeInfo.commandDetails,
      status: 'kProgress'
    }));  // NOTE: There are places where op details does not have the parsed command. Thats OK !
    dispatch(fetchOpStatus(newOpId)); // Start polling the op's status
    setMode('regular'); // Switch to regular mode after execution
    updateSdocWithOp(newOpId, null, nodeInfo.commandDetails);
  };

  const handleNodeSelect = () => {
    const newArray = _.cloneDeep(selectedCmd);
    const index = newArray.indexOf(nodeInfo.id);

    if (index === -1) {
      newArray.push(nodeInfo.id);
    } else {
      newArray.splice(index, 1);
    }
    setSelectedCmd(newArray);
  };


  const inferMainText = (commandDetails) => {
    if (commandDetails.resourceId) {
      const resourceName = resourceNames.find(r => r.resourceId == commandDetails.resourceId)?.resourceName;
      if (resourceName && !commandDetails.mainText.trim().startsWith("@[")) {
        return `@[${resourceName}](${commandDetails.resourceId}) ${commandDetails.mainText}`;
      }
    }
    return commandDetails.mainText;
  }

  // Get the status of the current operation
  const isRunning = currentOp?.status === 'kProgress';
  const isError = currentOp?.status === 'kError';
  const defaultMainText = inferMainText(commandDetails);

  return (
    <div
      className='mb-1'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0} // Make div focusable
    >
    <div className="d-flex">
        <div className="form-check mb-0 ms-1 pt-1">
          <input
            type="checkbox"
            className="form-check-input todo-done"
            style={{ boxShadow: "lightgrey 0px 0px 3px" }}
            checked={selectedCmd?.indexOf(nodeInfo.id) > -1}
            onChange={handleNodeSelect}
          />
        </div>
        <div className='w-100'>
          <div className="me-1" style={{ borderLeft: "1px solid lightgrey" }}>
            {mode === "initial" ? (
              // Initial Mode UI
              <div
                id="initialModeBox"
                style={{
                  border: "0px solid lightgrey",
                  boxShadow: "0px 0px 3px lightgrey",
                }}
              >
                <Row className="align-items-center">
                  <Col xs="auto" style={{ width: "20px", marginLeft: "10px" }}>
                    <i className="mdi mdi-console-line"></i>
                  </Col>
                  <Col>
                    <CmdMainTextBix
                      defaultValue={commandDetails.mainText}
                      onChange={(e) =>
                        handleCommandChange("MAIN_TEXT", e.target.value)
                      }
                      style={{
                        width: "100%",
                        border: "0px solid black",
                        resize: "none",
                        outline: "none",
                        fontFamily: "'Fira Code', monospace",
                        fontWeight: "lighter",
                        color: "rgba(2,2,2,0.55)",
                        fontSize: "0.5em",
                        backgroundColor: "transparent",
                        // marginTop: '8px',
                        verticalAlign: "middle",
                      }}
                      className='fira-code-block'
                    />
                    {/* <TextareaAutosize
              defaultValue={commandDetails.mainText}
              onChange={e => handleCommandChange('MAIN_TEXT', e.target.value)}
              style={{
                width: '100%',
                border: '0px solid black',
                resize: 'none',
                outline: 'none',
                fontFamily: 'monospace',
                fontWeight: 'lighter',
                color: 'rgba(2,2,2,0.55)',
                fontSize: '0.9em',
                backgroundColor: 'transparent',
                // marginTop: '8px',
                verticalAlign: 'middle',
              }}
            /> */}
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      onClick={handleRun}
                      style={{
                        boxShadow: "none",
                        padding: 0,
                        margin: 0,
                        marginRight: "10px",
                      }}
                    >
                      <i
                        className="uil uil-message me-1"
                        style={{
                          boxShadow: "none",
                          padding: 0,
                          margin: 0,
                          color: "grey",
                        }}
                      ></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              // Regular Mode UI
              <div className="ms-2 me-2">
                <Row className="align-items-center">
                  {/* <Col style={{ backgroundColor: '#f5f6f9' }}>
            <span className='mt-2'>
              Summary goes here 
            </span>

          </Col> */}
                </Row>

                {/* The same TextArea and other components from initial mode */}
                <Row className="align-items-start mt-1">
                  <Col xs="auto" style={{ maxWidth: "30px" }}>
                    <Dropdown className="e-caret-hide">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{
                          boxShadow: "none",
                          padding: 0,
                          margin: 0,
                          color: "grey",
                        }}
                        className="e-caret-hide"
                      >
                        {
                          commandDetails.resourceType === ResourceTypesEnum.kLoki ?
                          (<>
                              <span style={{ display: 'inline-block', marginRight: '2px' }}>
                                <img src={LogLogo} alt={'icon'} style={{ width: '15px', height: '15px' }} />
                              </span>
                          </>)
                          : (<>
                            <i className={iconClassFromResourceType(commandDetails.resourceType)}></i>                      
                          </>)
                        }
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* Dropdown menu items here */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col>
                    <TextareaAutosize
                      defaultValue={commandDetails.mainText}
                      onChange={(e) => onCommandChange(e.target.value)}
                      style={{
                        width: "100%",
                        border: "0px solid black",
                        resize: "none",
                        outline: "none",
                        fontFamily: "'Fira Code', monospace",
                        fontWeight: "lighter",
                        color: "rgba(2,2,2,0.55)",
                        fontSize: "0.9em",
                        backgroundColor: "transparent",
                        // marginTop: '8px',
                        verticalAlign: "middle",
                      }}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      onClick={handleRun}
                      style={{
                        boxShadow: "none",
                        padding: 0,
                        margin: 0,
                        marginRight: "10px",
                      }}
                    >
                      {waitingForResult ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i
                          className="uil uil-message me-1"
                          style={{
                            boxShadow: "none",
                            padding: 0,
                            margin: 0,
                            color: "grey",
                            fontSize: "0.75em",
                          }}
                        ></i>
                      )}

                      {/* <i className="mdi mdi-play" style={{ boxShadow: 'none', padding: 0, margin: 0, color: 'grey'}}></i> */}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <CommandStatus isRunning={false} currentOp={currentOp} commandDetails={commandDetails}/>
                </Row>
                <Row>
                  { // The bottom toolbar.
                  }
                </Row>
              </div>
            )}
          </div>
          {mode == "initial" ? (
            <>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "6px",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "light-grey",
                }}
              >
                {miniStatusInitial}
              </div>
            </>
          ) : (
            <></>
          )}
          <CmdBottomToolbar visibility={isToolbarVisible} />
        </div>
      </div>
    </div>
  );
};

// Helper function to submit command and get opId (update with your logic)
function submitCommand(commandDetails, opId) {
  // Logic to execute command with opId
  // The function does not need to return opId anymore
  // Define the API endpoint
  const apiEndpoint = '/api/ops/';

    // Create the request body
    const requestBody = {
      opId: opId,
      resourceId: commandDetails.resourceId,
      commandDetails: {
        mainText: commandDetails.mainText,
        resourceId: commandDetails.resourceId,
        // resourceId: getDefaultHostDetails().hostId,
        // Add other details as required by your backend
      }
    };
  
    console.log('Submitting an Op with following body: ');
    console.dir(requestBody);
    // Make a POST request to the backend
    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Operation submitted successfully:', data);
    })
    .catch(error => {
      console.error('Error submitting operation:', error);
    });

}

export default CommandNodeMain;
