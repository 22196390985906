import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { updateSdocAddNode, fetchSDoc, updateSdocUpdateNode, addOp } from '../../../../redux/ctx/actions';
import { uuid } from 'uuidv4';
import CommandNodeMain from './CommandNodeMain';
import { TrShellSimpleTopToolbar } from './TrShellTopToolbar';
import CreateWorkspaceModal from './CreateWorkspaceModal';
import { getDefaultHostDetails } from '../utils';
import { CmdMainTextBix } from './CmdMainTextBox';

const SimpleTextNodeMain = () => {
  return (<>
    <div className='ms-2 me-2'>
      <p>Text node</p>
    </div>
  </>);
}

const TrShellMain = ({ docId }) => {
  const [renderCount, setRenderCount] = useState(0);
  const [selectedCmd, setSelectedCmd] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const sdoc = useSelector(state =>
    state.ctx.sdocs.find(sdoc => sdoc.docId === docId)
  );

  const [nodeType, setNodeType] = useState('kText');
  const [nodeContent, setNodeContent] = useState('');
  const { trsApiResult, msg } = useSelector((state) => ({
    trsApiResult: state.VarItems.trsApiResult,
    msg: state.VarItems.msg,
  }));

  const handleWorkspaceModal = () => setShowModal(!showModal);
  const userDefaultHostResource = getDefaultHostDetails();

  useEffect(() => {
    if (!sdoc) {
      // If sdoc is not in the store, dispatch an action to fetch it
      dispatch(fetchSDoc(docId));
    }
  }, [dispatch, docId, sdoc]);

  // Whenever sodc changes, check if we need to add an Op to redux.
  // This is done for handling concurrent updates to TrShell.
  useEffect(() => {
    // Iterate over sdoc nodes and check for operations
    sdoc?.nodes.forEach(node => {
      if (node.type === 'kCommand' && node.opDetails) {
        // Dispatch action to add operation to Redux store
        dispatch(addOp(node.opDetails.opId, { opId: node.opDetails.opId, command: node.content, status: 'kProgress' }));
      }
    });
  }, [sdoc, dispatch]);

  useEffect(() => {
    if (trsApiResult === "success") {
      toast.success(msg);
    } else if (trsApiResult === "error") {
      toast.error(msg);
    }
  }, [trsApiResult, msg]);

  const handleAddNode = () => {
    const newNode = {
      type: nodeType,
      content: nodeContent,
      id: uuid(),
      // Add other properties as needed
    };
    dispatch(updateSdocAddNode(docId, newNode));
  };

  const handleCommandContentChange = (nodeId, updatedNode) => {
    dispatch(updateSdocUpdateNode(docId, updatedNode));
  }

  const updateSdocWithOp = (opId, commandNodeId, opStatus = null, newCommandDetails = null) => {
    // Logic to update the sdoc
    // ...
    const nodeToUpdate = sdoc.nodes.find(node => node.id === commandNodeId);
    if (nodeToUpdate) {
      const updatedNode = {
        ...nodeToUpdate,
        ...(newCommandDetails && {commandDetails: newCommandDetails}),
        opDetails: { 
          ...nodeToUpdate.opDetails, 
          opId: opId, 
          // ...(newCommandDetails && {commandDetails: newCommandDetails}),
          ...(opStatus && { status: opStatus }) // Update status only if provided
        }
      };
      console.log('Updated node: ' + JSON.stringify(updatedNode));
      dispatch(updateSdocUpdateNode(docId, updatedNode));  // TODO: This needs to change !!!! this will add node.
    }
  };

  if (!sdoc) {
    return <div>Loading...</div>;
  }
  console.log('Rendering TrShellMain, version: ' + sdoc.version + ' mv: ' + sdoc.majorVersion);
  
  // setRenderCount(renderCount + 1);
  return (
    <div className='mt-2 ps-0'>
      <TrShellSimpleTopToolbar sdocId={docId} selectedCmd={selectedCmd} handleWorkspaceModal={handleWorkspaceModal} />
      {/* <div className="sdoc-display">
        <h2>Sdoc JSON:</h2>
        <pre>{JSON.stringify(sdoc, null, 2)}</pre>
      </div> */}
      {/* <div className="node-add-form mb-3 mt-2">
        <select value={nodeType} onChange={e => setNodeType(e.target.value)}>
          <option value="kText">Text Node</option>
          <option value="kCommand">Command Node</option>
        </select>
        <input
          type="text"
          value={nodeContent}
          onChange={e => setNodeContent(e.target.value)}
          placeholder="Node Content"
        />
        <button onClick={handleAddNode}>Add Node</button>
      </div> */}

      {sdoc.nodes.map(node => {
        switch (node.type) {
          case 'kCommand':
            const key = node.id + (node.opDetails ? node.opDetails.opId : '');
            return <CommandNodeMain
                key={key} nodeInfo={node} commandDetails={node.commandDetails}
                updateSdocWithOp={(opId, opStatus, commandDetails) => updateSdocWithOp(opId, node.id, opStatus, commandDetails)} 
                onCommandChange={(updatedNode) => handleCommandContentChange(node.id, updatedNode)}
                setSelectedCmd={setSelectedCmd}
                selectedCmd={selectedCmd}
                defaultHostResource={userDefaultHostResource}
              />;
          case 'kText':
            return <SimpleTextNodeMain key={node.id} nodeInfo={node} />;
          default:
            return null;
        }
      })}
      {showModal && (
        <CreateWorkspaceModal
          showModal={showModal}
          onCancel={handleWorkspaceModal}       
          selectedCmd={selectedCmd}   
          nodes={sdoc.nodes}          
        />
      )}
    </div>
  );
};

export default TrShellMain;
