import { ChatActionTypes } from './constants';

export const sendMessage = (message) => ({
  type: ChatActionTypes.SEND_MESSAGE,
  payload: message,
});

export const addMessage = (message) => ({
  type: ChatActionTypes.ADD_MESSAGE,
  payload: message,
});

export const addMessageToConversation = (conversationId, message) => ({
  type: ChatActionTypes.ADD_MESSAGE_TO_CONVERSATION,
  payload: {conversationId, message},
})

export const newConversation = (conversationId, conversationContext, message) => ({
  type: ChatActionTypes.NEW_CONVERSATION,
  payload: {conversationId, conversationContext, message}
})