import { APICore } from "./apiCore";

const api = new APICore();

// profile update
function updateProfile(params) {
  const baseUrl = "/users/";
  return api.update(`${baseUrl}`, params);
}

// get host list by userid
function getHostList(params) {
  const baseUrl = `/hosts/user/${params.userId}`;
  return api.get(`${baseUrl}`, params);
}

// delete host by id
function deleteHost(params) {
  const baseUrl = `/hosts/${params.hostId}`;
  return api.delete(`${baseUrl}`, params);
}

// Create host
function createHost(params) {
  const baseUrl = `/hosts`;
  return api.create(`${baseUrl}`, params);
}

// Update host
function updateHost(params) {
  const baseUrl = `/hosts/${params.hostId}`;
  return api.update(`${baseUrl}`, params);
}

// get account list by userid
function getAccountList(params) {
  const baseUrl = `/account/user/${params.userId}`;
  return api.get(`${baseUrl}`, params);
}

// delete account by id
function deleteAccount(params) {
  const baseUrl = `/account/${params.accountId}`;
  return api.delete(`${baseUrl}`, params);
}

// Create Account
function createAccount(params) {
  const baseUrl = `/account`;
  return api.create(`${baseUrl}`, params);
}

// Update Account
function updateAccount(params) {
  const baseUrl = `/account/${params.accountId}`;
  return api.update(`${baseUrl}`, params);
}

// get org list by userid
function getOrgListApi(params) {
  const baseUrl = `/orgs/user/${params.userId}`;
  return api.get(`${baseUrl}`, params);
}

// delete org by id
function deleteOrgApi(params) {
  const baseUrl = `/orgs/${params.orgId}`;
  return api.delete(`${baseUrl}`, params);
}

// Create org
function createOrgApi(params) {
  const baseUrl = `/orgs`;
  return api.create(`${baseUrl}`, params);
}

// Invite Member
function inviteMemberApi(params) {
  const baseUrl = `/orgOps`;
  return api.create(`${baseUrl}`, params);
}

// Org Operations
function getOrgOpsApi(params) {
  const baseUrl = `/orgOps`;
  return api.create(`${baseUrl}`, params);
}

// User Operations
function getUserOpsApi(params) {
  const baseUrl = `/userOps`;
  return api.get(`${baseUrl}`, params);
}

// leave org by id
function leaveOrgApi(params) {
  const baseUrl = `/orgs/membership/${params.entryId}`;
  return api.delete(`${baseUrl}`, params);
}

// get assistant context list by userid
function getAssistantContextListAPI(params) {
  const baseUrl = `/assistantcontext/user/${params.userId}`;
  return api.get(`${baseUrl}`, params);
}

// delete assistant context by id
function deleteAssistantContextAPI(params) {
  const baseUrl = `/assistantcontext/${params.contextId}`;
  return api.delete(`${baseUrl}`, params);
}

// Create assistant context
function createAssistantContextAPI(params) {
  const baseUrl = `/assistantcontext`;
  return api.createWithFiles(`${baseUrl}`, params);
}

// Update assistant context
function updateAssistantContextAPI(params) {
  const contextId = params?.contextId;
  const baseUrl = `/assistantcontext/${contextId}`;
  return api.updateWithFiles(`${baseUrl}`, params);
}

export {
  createAccount,
  createHost,
  deleteAccount,
  deleteHost,
  getAccountList,
  getHostList,
  updateHost,
  updateAccount,
  updateProfile,
  getOrgListApi,
  deleteOrgApi,
  createOrgApi,
  inviteMemberApi,
  getOrgOpsApi,
  getUserOpsApi,
  leaveOrgApi,
  getAssistantContextListAPI,
  deleteAssistantContextAPI,
  createAssistantContextAPI,
  updateAssistantContextAPI,
};
