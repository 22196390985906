import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import {
  addMessage,
  addMessageToConversation,
  sendMessage,
} from "../../../../redux/assistantchat/actions";
import {
  initWebSocket,
  sendMessage as sendWebSocketMessage,
} from "../../../../utils/websocket";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { Button, Col, Dropdown, FloatingLabel, Form, Row } from "react-bootstrap";
import { constructCommandNode } from "../utils";
import { updateSdocAddNode } from "../../../../redux/actions";
import TemplateModal from "./TemplateModal";
import { FormattedModelResponse } from "./FormattedModelResponse";
import { TrPlan } from "./TrPlan";
import { TextareaAutosize } from "@mui/material";

const templateOptions = [
  { value: "question", label: "Ask Question" },
  { value: "command", label: "Analyze Command Output" },
];
const StringConstants = {
  userQuery: "userQuery",
  modelResponse: "modelResponse",
};

const showOnlyJsonDumpInChat = false;

const userMessageContainer = (content, index) => {
  return (
    <li className={classnames("clearfix")}>
      <div className="conversation-text">
        <div className={`ctext-wrap ${index % 2 === 0 ? "" : "reply-text"}`}>
          <p style={{ display: "flex", alignItems: "center" }}>
            <i
              className="mdi mdi-account"
              style={{ fontSize: "18px", width: 20 }}
            ></i>{" "}
            {":"}
            <span style={{ marginLeft: "5px" }}>{content}</span>
          </p>
        </div>
        {/* <TrPlan/> */}
      </div>
    </li>
  );
};

const ModelMessageContainer = ({contentJson, index}) => {
  const nonFormattedResponse = !contentJson.modelResponse.formattedResponse;
  let content = (<></>);
  if (nonFormattedResponse) {
    content = contentJson.modelResponse.text;
  } else {
    content = 
      (<FormattedModelResponse formattedResponse={contentJson.modelResponse.formattedResponse}/>);
  }

  return (
    <>
      <li className={classnames("clearfix")}>
        <div className="conversation-text">
          <div className={`ctext-wrap ${index % 2 === 0 ? "" : "reply-text"}`}>
            <i
              className="uil uil-robot rounded"
              style={{ fontSize: "18px", width: 20 }}
            ></i>
            {content}
          </div>
        </div>
      </li>
    </>
  );
};

const ConversationItem = ({ contentJson, index }) => {
  if (showOnlyJsonDumpInChat) {
    return <>{JSON.stringify(contentJson, null, 2)}</>;
  }

  if (contentJson.type == StringConstants.userQuery) {
    const nonTemplateQuery = !contentJson.query.template;
    if (nonTemplateQuery) {
      return userMessageContainer(<>{contentJson.query.text}</>, index);
    }
  }

  if (contentJson.type == StringConstants.modelResponse) {
    return (<ModelMessageContainer key={contentJson.id} contentJson={contentJson} index={index}/>);
  }

  return <></>;
};

const makeSelectableData = (opsData) => {
  return opsData.map((opsItem) => ({
    ...opsItem,
    value: opsItem.opId,
    label: opsItem.command || opsItem.commandDetails.mainText,
  }));
}

const TemplateSelectionRow = ({handleMenuClick, template, setTemplate, setOperations, opsData}) => {
  return (
    <>
      <form className="row gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between">
          <div className="col-12" style={{marginTop: '0px'}}>
            <div className="d-flex align-items-center">
              {/* <div className="me-2" style={{ width: 75 }}>
                Template:
              </div> */}
              {/* <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: 200,
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    width: 200,
                  }),
                }}
                isMulti={false}
                options={templateOptions}
                className="react-select"
                classNamePrefix="react-select"
                defaultValue={templateOptions[0]}
                onChange={(selectedOption) => setTemplate(selectedOption)}
              ></Select> */}
              <div style={{width: '97%'}}>
              <FloatingLabel controlId="floatingSelect" label="Select Template">
                <Form.Select  aria-label="Floating label select example"
                style={{
                  border: '0px solid black',
                }}
                options={templateOptions}
                onChange={(selectedOption) => setTemplate(selectedOption)}
                >
                  <option value="question">Ask Question</option>
                  <option value="command">Analyze Command Output</option>
                </Form.Select>
              </FloatingLabel>
              </div>
              {template?.value === "question" && (
                <Dropdown className="float-end" align="end">
                  <Dropdown.Toggle
                    variant="link"
                    className="arrow-none card-drop p-0 shadow-none ms-1"
                  >
                    <i className="mdi mdi-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleMenuClick("jira")}>
                      Attach Jira
                    </Dropdown.Item>
                    <Dropdown.Item>Attach Code</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleMenuClick("result")}>
                      Attach Result
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
          {template?.value === "command" && (
            <div className="col-12">
              <div className="d-flex align-items-center w-auto">
                <div className="me-2" style={{ width: 75 }}>
                  OpIds:
                </div>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: 200,
                    }),
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      width: 200,
                    }),
                  }}
                  isMulti={true}
                  options={makeSelectableData(opsData)}
                  className="react-select"
                  classNamePrefix="react-select"                  
                  onChange={(selectedOption) => setOperations(selectedOption)}
                ></Select>
              </div>
            </div>
          )}
        </form> 
    </>
  );

}

const Chat = ({ conversationId }) => {
  const currentConversationId = conversationId || "123";
  const [inputText, setInputText] = useState("");
  const [template, setTemplate] = useState(templateOptions[0]);
  const [operations, setOperations] = useState([]);
  const [shareToggle, setShareToggle] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selJiras, setSelJiras] = useState([]);
  const messages = useSelector(
    (state) => state.chat.conversations[currentConversationId]
  );
  const opsData = useSelector(
    (state) => state.ctx.ops || []
  );

  console.log('OpsData in Chat: ');
  console.dir(opsData);
  // const messages = useSelector(state => state.chat.conversations[currentConversationId] || []);
  const dispatch = useDispatch();

  const handleShareClick = () => setShareToggle(!shareToggle);

  const handleMenuClick = (type) => {
    setModalType(type);
    handleShareClick();
  };

  useEffect(() => {
    // Initialize WebSocket connection
    initWebSocket(dispatch);
  }, [dispatch]);

  const handleSendMessage = () => {
    if (inputText.trim()) {
      const userText = inputText.trim();
      const activityId = uuidv4();
      const message = {
        id: activityId,
        text: "Message from Assistant Panel",
        sender: "user",
        from: "assistantPanel",
        data: {
          userText,
        },
        // New fields.
        type: "userQuery",
        query: {
          text: userText,
        },
        conversationId: currentConversationId,
        activityId,
      };
      if (template?.value) {
        message.template = template.value;
      }
      if (operations?.length > 0) {
        const seletedOperations = operations.map(item => item.value);
        message.operations = seletedOperations;
      }
      if (selJiras?.length > 0) {
        const jiraIds = selJiras.map(item => item.value);
        message.jiraIds = jiraIds;
      }
      // Dispatch addMessage action for optimistic UI update
      dispatch(addMessageToConversation(currentConversationId, message));
      dispatch(sendMessage(message));

      // Clear input field
      setInputText("");
    }
  };

  return (
    <div className="chat-container">
      <SimpleBar style={{ height: "530px", width: "100%" }}>
        <ul className="assistant-conversation-list px-3 mt-1">
          {/* <ul className="rptless-conversation-list px-3"> */}
          {messages?.map((message, index) => (
            <ConversationItem
              key={message.id}
              contentJson={message}
              index={index}
            />
          ))}
        </ul>
      </SimpleBar>
      <hr className="bg-dark-lighten" />
      <div className="ms-2 me-2 mb-2">
        <TemplateSelectionRow handleMenuClick={handleMenuClick} setOperations={setOperations}
            setTemplate={setTemplate} 
            template={template}
            opsData={opsData}
            />
        <div className="app-search">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-2 position-relative border rounded-3">
              <TextareaAutosize
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                style={{
                    width: "94%",
                    padding: "10px",
                    marginLeft: '5px',
                    border: '0px solid black',
                    resize: 'none',
                    outline: 'none',
                    verticalAlign: 'middle',
                    borderRadius: '10px',
                }}
                placeholder="Converse with assistant..."
                maxRows={4}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                size
              />
              {/* <input
                type="text"
                className="form-control sharpshooter-converse-input"
                placeholder="Converse with sharpshooter..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                // onKeyPress={handleKeyPress}
              /> */}
              <Button variant="link" onClick={handleSendMessage} style={{ boxShadow: 'none', padding: 0, margin: 0 }}>
                <i className="uil uil-message me-1"
                    style={{ boxShadow: 'none', padding: 0, margin: 0, color: 'grey'}}></i>
              </Button>
              {/* <button
                className="sharpshooter-converse"
                onClick={handleSendMessage}
              >
                <span className="uil uil-message"></span>
              </button> */}
            </div>
          </form>
        </div>
      </div>

      {/* <div className="message-input">
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div> */}
      {shareToggle && (
        <TemplateModal
          shareToggle={shareToggle}
          onCancel={handleShareClick}
          modalType={modalType}
          setOperations={setOperations}
          setSelJiras={setSelJiras}
          opsData={makeSelectableData(opsData)}
        />
      )}
    </div>
  );
};

export default Chat;
