import { SettingsActionTypes } from "./constants";

export const updateProfile = (params) => ({
  type: SettingsActionTypes.UPDATE_PROFILE,
  payload: params,
});

export const getHosts = (params) => ({
  type: SettingsActionTypes.GET_HOSTS,
  payload: params,
});

export const getHostsApiResponseSuccess = (data) => ({
  type: SettingsActionTypes.GET_HOSTS_API_SUCCESS,
  payload: data,
});

export const deleteHost = (params) => ({
  type: SettingsActionTypes.DELETE_HOST,
  payload: params,
});

export const saveHost = (params) => ({
  type: SettingsActionTypes.SAVE_HOST,
  payload: params,
});

export const saveHostApiResponseSuccess = (data) => ({
  type: SettingsActionTypes.SAVE_HOST_API_SUCCESS,
  payload: data,
});

export const updateHost = (params) => ({
  type: SettingsActionTypes.UPDATE_HOST,
  payload: params,
});

export const getAccounts = (params) => ({
  type: SettingsActionTypes.GET_ACCOUNTS,
  payload: params,
});

export const getAccountsApiResponseSuccess = (data) => ({
  type: SettingsActionTypes.GET_ACCOUNTS_API_SUCCESS,
  payload: data,
});

export const deleteAccount = (params) => ({
  type: SettingsActionTypes.DELETE_ACCOUNT,
  payload: params,
});

export const saveAccount = (params) => ({
  type: SettingsActionTypes.SAVE_ACCOUNT,
  payload: params,
});

export const updateAccount = (params) => ({
  type: SettingsActionTypes.UPDATE_ACCOUNT,
  payload: params,
});

export const getOrgsAction = (params) => ({
  type: SettingsActionTypes.GET_ORGS,
  payload: params,
});

export const getOrgsApiResponseSuccess = (data) => ({
  type: SettingsActionTypes.GET_ORGS_API_SUCCESS,
  payload: data,
});

export const deleteOrgAction = (params) => ({
  type: SettingsActionTypes.DELETE_ORG,
  payload: params,
});

export const saveOrgAction = (params) => ({
  type: SettingsActionTypes.SAVE_ORG,
  payload: params,
});

export const inviteMemberAction = (params) => ({
  type: SettingsActionTypes.INVITE_MEMBER,
  payload: params,
});

export const orgMemberAction = (params) => ({
  type: SettingsActionTypes.GET_ORG_MEMBER,
  payload: params,
});

export const getOrgsMembersResponseSuccess = (data) => ({
  type: SettingsActionTypes.GET_ORG_MEMBER_API_SUCCESS,
  payload: data,
});

export const orgInvitationAction = (params) => ({
  type: SettingsActionTypes.GET_ORG_INVITES,
  payload: params,
});

export const getOrgsInviteResponseSuccess = (data) => ({
  type: SettingsActionTypes.GET_ORG_INVITES_API_SUCCESS,
  payload: data,
});

export const removeOrgMemberAction = (params) => ({
  type: SettingsActionTypes.REMOVE_ORG_MEMBER,
  payload: params,
});

export const acceptOrgInviteAction = (params) => ({
  type: SettingsActionTypes.ACCEPT_ORG_INVITATION,
  payload: params,
});

export const leaveOrgAction = (params) => ({
  type: SettingsActionTypes.LEAVE_ORG,
  payload: params,
});

export const getAssistantContextsAction = (params) => ({
  type: SettingsActionTypes.GET_ASSISTANT_CONTEXTS,
  payload: params,
});

export const getAssistantContextsApiResponseSuccess = (data) => ({
  type: SettingsActionTypes.GET_ASSISTANT_CONTEXTS_API_SUCCESS,
  payload: data,
});

export const deleteAssistantContextAction = (params) => ({
  type: SettingsActionTypes.DELETE_ASSISTANT_CONTEXT,
  payload: params,
});

export const saveAssistantContextAction = (params) => ({
  type: SettingsActionTypes.SAVE_ASSISTANT_CONTEXT,
  payload: params,
});

export const updateAssistantContext = (params) => ({
  type: SettingsActionTypes.UPDATE_ASSISTANT_CONTEXT,
  payload: params,
});
