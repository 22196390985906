import React, { useEffect, useRef, useState } from 'react';
import { Grid, Slide } from '@mui/material';
import MonacoEditor from 'react-monaco-editor';
import { FileTree } from './FileTree';
import { Col, Row } from 'react-bootstrap';

const FileExplorer = ({ fileData }) => {
  return (
    <div style={{ width: '300px' }}>
      <FileTree data={fileData} />
    </div>
  );
};

const FileEditor = ({ code, onChange }) => {
  const editorRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (editorRef.current) {
        editorRef.current.editor.layout();
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const editorOptions = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: false,
    cursorStyle: 'line',
    automaticLayout: true,
  };

  return (
    <div style={{ flexGrow: 1, height: '400px', width: '100%' }}>
    <MonacoEditor
      ref={editorRef}
      height="100%"
      width="100%"
      language="javascript"
      // theme="vs-dark"
      value={code}
      options={editorOptions}
      onChange={onChange}
    />
    </div>
  );
};



const FileEditorWithExplorer = ({ fileData, selectedFileContent, onFileChange, explorerOpen }) => {
  return (
    <Row noGutters>
      {explorerOpen && (
        <Col md="3" >
          <div >
            <FileExplorer fileData={fileData} />
          </div>
        </Col>
      )}
      <Col md={explorerOpen ? "9" : "12"}>
        <FileEditor code={selectedFileContent} onChange={onFileChange} />
      </Col>
    </Row>
  );

  // return (
  //   <Grid container>
  //   <Slide direction="right" in={explorerOpen} mountOnEnter unmountOnExit timeout={500}>
  //     <Grid item>
  //       <FileExplorer fileData={fileData} />
  //     </Grid>
  //   </Slide>
  //   <Grid item xs>
  //     <FileEditor code={selectedFileContent} onChange={onFileChange} />
  //   </Grid>
  // </Grid>

  // );
};

export default FileEditorWithExplorer;
