import { useState } from "react"
import TextareaAutosize from 'react-textarea-autosize';


export const InlineEdit = ({ value, setValue }) => {
  const [editingValue, setEditingValue] = useState(value);

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <>
    <TextareaAutosize
      style={{
        backgroundColor: 'inherit',
        fontSize:'20px', fontFamily:'"Nunito", sans-serif',
        // color: '#303030',
        paddingLeft:'7px',
        width:"600px",
        border:'0px solid grey',
        resize:'none',  /* takes away the ugly re-sizing icon found in the botom right corner */
        outline:'none',
        paddingTop: '5px',
      }}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      defaultValue={ editingValue }/> 

    {/* <input
      type="text"
      aria-label="Field name"
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    /> */}
    </>
  );
};

const EditableLabel = (props) => {
  const [content, setContent] = useState(props.initialContent);
  const [editable, setEditable] = useState(false);

  if (editable) {
    return (<>
      <div className='cmd-main-box' style={{
          fontFamily: "courier, monospace",
                // backgroundColor: 'white',
                padding: '5px',
          
          }}>
          <TextareaAutosize
              className='cmd-main-text'
              style={{
                // backgroundColor: '#F8FAFC',
                paddingLeft: '10px',
                width:"100%",
                border:'0px solid grey',
                resize:'none',  /* takes away the ugly re-sizing icon found in the botom right corner */
                outline:'none',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
              onChange={(e) => setContent(e.target.value)}
              defaultValue={ content }/>
      </div>

    </>);
  } else {
    return (<>
      <span className="mb-2" style={{fontSize:'20px', fontFamily:'"Nunito", sans-serif', color: '#303030', paddingLeft:'7px'}}>
        {content}
      </span>
    </>);
  }
}