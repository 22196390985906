// src/utils/websocket.js
let socket = null;

export const initWebSocket = (dispatch) => {
  socket = new WebSocket('ws://localhost:50000');

  socket.onopen = () => {
    console.log('WebSocket Connected');
  };

  socket.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.log('New message from socket: ');
    console.dir(message);
    dispatch({ type: 'ADD_MESSAGE_TO_CONVERSATION', payload: {conversationId: message.conversationId, message}});
    // dispatch({ type: 'ADD_MESSAGE', payload: message});
  };

  socket.onclose = () => {
    console.log('WebSocket Disconnected');
    // Optionally attempt to reconnect or handle disconnection
  };

  socket.onerror = (error) => {
    console.error('WebSocket Error:', error);
  };
};

export const sendNewConversationMessage = (payload) => {
  const {conversationId, conversationContext, message } = payload;
  if (socket && socket.readyState === WebSocket.OPEN) {
    const msgToBackend = {
      type: 'create-and-subscribe',
      conversationId,
      conversationContext
    }
    if (message) {
      msgToBackend.initialUserQuery = message;
    }
    console.log('Sending create-and-subscribe to WSS');
    socket.send(JSON.stringify(msgToBackend));
  } else {
    console.error('Error in sending new conversation message. WebSocket is not connected.');
  }
}

export const sendMessage = (message) => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    console.log('Sending message tp WSS: ');
    console.dir(message);
    const msgToBackend = {
      ...message,
      // type: 'userQuery',
      // conversationId: '123',
      // activityId: message.id,
    }
    socket.send(JSON.stringify(msgToBackend));
  } else {
    console.error('WebSocket is not connected.');
  }
};
