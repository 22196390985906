import { UserActivityActionTypes } from "./constants";

// actions.js

export const getUserActivityList = (params) => ({
  type: UserActivityActionTypes.GET_USER_ACTIVITY,
  payload: params,
});

export const getUserActivityListApiResponseSuccess = (data) => ({
  type: UserActivityActionTypes.GET_USER_ACTIVITY_API_SUCCESS,
  payload: data,
});

export const getAllUserList = (tenantId) => ({
  type: UserActivityActionTypes.GET_ALL_USER,
  payload: {tenantId},
});

export const getAllUserListApiResponseSuccess = (data) => ({
  type: UserActivityActionTypes.GET_ALL_USER_API_SUCCESS,
  payload: data,
});

