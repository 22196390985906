import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-bootstrap-typeahead/css/Typeahead.css";
import AccessMenu from "./AccessMenu";
import { useDispatch, useSelector } from "react-redux";
import { getUserAndGroups, shareTrs } from "../../../../redux/actions";

const GRANT_ACCESS = {
  restricted: "Restricted",
  public: "Anyone with the link",
};

const ShareResultModal = (props) => {
  const { onCancel, shareToggle, trsId } = props;
  const dispatch = useDispatch();
  const { userAndGroups } = useSelector((state) => state.VarItems);
  const [label, setLabel] = useState("restricted");

  const [multiSelections, setMultiSelections] = useState([]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (shareToggle) {
      dispatch(getUserAndGroups());
    }
  }, [shareToggle]);

  useEffect(() => {
    if (userAndGroups?.length > 0) {
      setOptions(userAndGroups);
    }
  }, [userAndGroups]);

  const onFormSubmit = () => {
    const params = {
      trsId,
      generalAccess: label,
      peopleAccess: multiSelections.map((item) => {
        return {
          ...item,
          access: item.access || "kAllowedWithDataRestrictions",
        };
      }),
    };
    dispatch(shareTrs(params));
    onCancel();
  };

  const updateAccess = (index, access) => {
    const newData = cloneDeep(multiSelections);
    newData[index].access = access;
    setMultiSelections(newData);
  };

  return (
    <Modal show={shareToggle} onHide={onCancel} centered>
      <Modal.Header
        className="share-modal-header"
        closeButton
        onHide={onCancel}
      >
        <Modal.Title>Share with Peers</Modal.Title>
      </Modal.Header>
      <div className="p-1">
        <Modal.Body className="px-3 pt-3 pb-0">
          <Form.Group as={Col} controlId="formGridState" className="mb-3">
            <Typeahead
              id="select3"
              labelKey="name"
              multiple
              onChange={setMultiSelections}
              size="lg"
              options={options}
              placeholder="Add people and groups"
              selected={multiSelections}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState" className="mb-3">
            <h5 className="header-title mt-0">People with access</h5>
            <ListGroup>
              {multiSelections.map((item, index) => (
                <AccessMenu
                  item={item}
                  index={index}
                  updateAccess={updateAccess}
                />
              ))}
            </ListGroup>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState" className="mb-3">
            <h5 className="header-title mt-0">General access</h5>
            <Row className="d-flex align-items-center">
              <Col className="col-12 d-flex align-items-center">
                {label === "restricted" ? (
                  <i className="uil uil-unlock font-14 access-icon-bg" />
                ) : (
                  <i className="uil uil-globe font-14 access-icon-bg" />
                )}
                <div>
                  <DropdownButton
                    size="sm"
                    variant="outline-secondary"
                    className="btn-grant-access"
                    title={GRANT_ACCESS[label]}
                  >
                    {Object.keys(GRANT_ACCESS).map((itemKey) => (
                      <Dropdown.Item
                        href="#"
                        key={itemKey}
                        onClick={() => setLabel(itemKey)}
                      >
                        <Row>
                          <Col className="col-1 p-0">
                            {itemKey === label && (
                              <i className="uil uil-check font-16 text-primary" />
                            )}
                          </Col>
                          <Col className="col-10">{GRANT_ACCESS[itemKey]}</Col>
                        </Row>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <div className="ps-2">
                    <small>
                      {label === "restricted"
                        ? "Only people with access can open with the link"
                        : "Anyone on the Internet with the link can view"}
                    </small>
                  </div>
                </div>
              </Col>
            </Row>
          </Form.Group>

          <div className="pb-2">
            <CopyToClipboard
              text={window.location.href}              
            >
              <Button
                variant="outline-primary"
                className="me-2"                
              >
                <i className="uil-link-h"></i> Copy link
              </Button>
            </CopyToClipboard>
            <Button
              className="float-end"
              variant="primary"
              disabled={multiSelections.length === 0}
              onClick={onFormSubmit}
            >
              Done
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ShareResultModal;
