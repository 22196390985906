import { APICore } from "./apiCore";

const api = new APICore();

// commandSequence
function getCommandSequence(params) {
  const baseUrl = "/command-sequence";
  return api.get(`${baseUrl}`, params);
}

// commandSequence
function deleteCommandSequenceApi(params) {
  const baseUrl = "/command-sequence";
  return api.delete(`${baseUrl}/${params.sequenceId}`);
}

// update commandSequence
function updateCommandSequenceApi(params) {
  const baseUrl = "/command-sequence";
  return api.update(`${baseUrl}`, params);
}

export { deleteCommandSequenceApi, getCommandSequence, updateCommandSequenceApi };