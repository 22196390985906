import React, { useState, useEffect } from 'react';
import { XTerm } from 'xterm-for-react';
import { Container } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

export const CloudShell = () => {
  const xtermRef = React.useRef(null);
  const [ws, setWs] = useState(null);
  const [commandBuffer, setCommandBuffer] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [sessionId, setSessionId] = useState(uuidv4());

  useEffect(() => {
    const newWs = new WebSocket('ws://localhost:8081/');
    newWs.onopen = () => handleWebSocketOpen(newWs);
    newWs.onmessage = event => handleWebSocketMessage(event);
    newWs.onerror = error => console.error('WebSocket error:', error);
    newWs.onclose = () => console.log('WebSocket connection closed');
    setWs(newWs);

    return () => newWs.close();
  }, []);

  const handleTerminalData = (data) => {
    if (data === '\r') { // Enter key is pressed
      handleEnterKey();
    } else {
      setCommandBuffer(prev => prev + data);
    }
  };

  const handleEnterKey = () => {
    if (commandBuffer.endsWith('\\')) {
      setCommandBuffer(prev => prev.slice(0, -1) + '\n');
    } else {
      sendCommandToServer(commandBuffer, ws);
      xtermRef.current.terminal.writeln('');
      setCommandBuffer('');
      setCommandHistory(prev => [...prev, commandBuffer]);
    }
  };

  const sendCommandToServer = (command, webSocket) => {
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      webSocket.send(JSON.stringify({ type: 'command', command: command, sessionId }));
    }
  };

  const handleWebSocketOpen = (webSocket) => {
    console.log('WebSocket connection established');
    registerCloudShellSession(webSocket);
  };

  const registerCloudShellSession = (webSocket) => {
    webSocket.send(JSON.stringify({
      type: 'register-cloudshell',
      sessionId: sessionId,
      agentId: 'demo-agent',
    }));
  };

  const handleWebSocketMessage = (event) => {
    const message = JSON.parse(event.data);
    if (message.type === 'command-result') {
      xtermRef.current.terminal.write(message.result);
    }
  };

  return (
    <Container>
      <XTerm 
        ref={xtermRef} 
        onData={handleTerminalData} 
        options={{ cursorBlink: true }}
        style={{ height: '80vh', width: '100%' }}
      />
      {/* Optional: Display command history */}
      <div>
        {commandHistory.map((cmd, index) => (
          <p key={index}>{cmd}</p>
        ))}
      </div>
    </Container>
  );
};
