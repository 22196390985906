
import * as actionTypes from './constants';

// Action to fetch an sdoc by docId
export const fetchSDoc = (docId) => ({
  type: actionTypes.FETCH_SDOC,
  payload: { docId }
});

// Action to update an sdoc in the store
export const updateSdocInStore = (sdoc) => ({
  type: actionTypes.UPDATE_SDOC_IN_STORE,
  payload: sdoc
});  // NOTE: Sagas should not listen for this, and not update backend for this action, else we will go into a loop.

// Action to add a new node to an sdoc
export const updateSdocAddNode = (docId, node) => ({
  type: actionTypes.UPDATE_SDOC_ADD_NODE,
  payload: { docId, node }
});

// Action to delete a node from an sdoc
export const updateSdocDeleteNode = (docId, nodeId) => ({
  type: actionTypes.UPDATE_SDOC_DELETE_NODE,
  payload: { docId, nodeId }
});

// Action to update a node in an sdoc
export const updateSdocUpdateNode = (docId, node) => ({
  type: actionTypes.UPDATE_SDOC_UPDATE_NODE,
  payload: { docId, node }
});

// Action to add vars to an sdoc
export const updateSdocAddVars = (docId, vars) => ({
  type: actionTypes.UPDATE_SDOC_ADD_VARS,
  payload: { docId, vars }
});

// Action to update vars in an sdoc
export const updateSdocUpdateVars = (docId, vars) => ({
  type: actionTypes.UPDATE_SDOC_UPDATE_VARS,
  payload: { docId, vars }
});

// Action to add a new op
// Usage :
//    addOp(opId, {
//      opId: '',
//      resourceId: '',
//      commandDetails: {
//        mainText: '',
//        extraText: '',
//        additionalFieldsMap: {}
//      }
//    })
export const addOp = (opId, op) => ({
  type: actionTypes.ADD_OP,
  payload: {opId, op}
});

// Action to update the status of an op
export const updateOpStatus = (opId, responseData) => ({
  type: actionTypes.UPDATE_OP_STATUS,
  payload: { opId, responseData }
});

export const fetchOpStatus = (opId) => ({
  type: actionTypes.FETCH_OP_STATUS,
  payload: { opId }
});

export const updateWsDoc = (wsDoc) => ({
  type: actionTypes.UPDATE_WSDOC,
  payload: { wsDoc }
});