export const sampleLogs = [
  {
    "timestamp": "2023-12-10T17:00:00",
    "source": "IngestionService",
    "content": "S3 write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:00:12",
    "source": "IngestionService",
    "content": "S3 write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:00:24",
    "source": "IngestionService",
    "content": "S3 write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:00:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:00:48",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:01:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:01:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:01:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:01:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:01:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:02:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:02:12",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:02:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:02:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:02:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:03:00",
    "source": "IngestionService",
    "content": "S3 write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:03:12",
    "source": "IngestionService",
    "content": "S3 write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:03:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:03:36",
    "source": "IngestionService",
    "content": "S3 write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:03:48",
    "source": "IngestionService",
    "content": "S3 write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:04:00",
    "source": "IngestionService",
    "content": "S3 write for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:04:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:04:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:04:36",
    "source": "IngestionService",
    "content": "Error writing data to S3 for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:04:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:05:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:05:12",
    "source": "IngestionService",
    "content": "S3 write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:05:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:05:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:05:48",
    "source": "IngestionService",
    "content": "S3 write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:06:00",
    "source": "IngestionService",
    "content": "S3 write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:06:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:06:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:06:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:06:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:07:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:07:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:07:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:07:36",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:07:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:08:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:08:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:08:24",
    "source": "IngestionService",
    "content": "S3 write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:08:36",
    "source": "IngestionService",
    "content": "S3 write for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:08:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:09:00",
    "source": "IngestionService",
    "content": "S3 write for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:09:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:09:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:09:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:09:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:10:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:10:12",
    "source": "IngestionService",
    "content": "S3 write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:10:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:10:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:10:48",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:11:00",
    "source": "IngestionService",
    "content": "S3 write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:11:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:11:24",
    "source": "IngestionService",
    "content": "S3 write for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:11:36",
    "source": "IngestionService",
    "content": "S3 write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:11:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:12:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:12:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:12:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:12:36",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:12:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:13:00",
    "source": "IngestionService",
    "content": "S3 write for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:13:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:13:24",
    "source": "IngestionService",
    "content": "S3 write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:13:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:13:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:14:00",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:14:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:14:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:14:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:14:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:15:00",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:15:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:15:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:15:36",
    "source": "IngestionService",
    "content": "S3 write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:15:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:16:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:16:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:16:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:16:36",
    "source": "IngestionService",
    "content": "S3 write for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:16:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:17:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:17:12",
    "source": "IngestionService",
    "content": "S3 write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:17:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:17:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:17:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:18:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:18:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:18:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:18:36",
    "source": "IngestionService",
    "content": "S3 write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:18:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:19:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:19:12",
    "source": "IngestionService",
    "content": "S3 write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:19:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:19:36",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:19:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:20:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:20:12",
    "source": "IngestionService",
    "content": "S3 write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:20:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:20:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:20:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:21:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:21:12",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:21:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:21:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:21:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:22:00",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:22:12",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:22:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:22:36",
    "source": "IngestionService",
    "content": "S3 write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:22:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:23:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:23:12",
    "source": "IngestionService",
    "content": "S3 write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:23:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:23:36",
    "source": "IngestionService",
    "content": "S3 write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:23:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:24:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:24:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:24:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:24:36",
    "source": "IngestionService",
    "content": "S3 write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:24:48",
    "source": "IngestionService",
    "content": "S3 write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:25:00",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:25:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:25:24",
    "source": "IngestionService",
    "content": "S3 write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:25:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:25:48",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:26:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:26:12",
    "source": "IngestionService",
    "content": "S3 write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:26:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:26:36",
    "source": "IngestionService",
    "content": "S3 write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:26:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:27:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:27:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:27:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:27:36",
    "source": "IngestionService",
    "content": "S3 write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:27:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:28:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:28:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:28:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:28:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:28:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:29:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:29:12",
    "source": "IngestionService",
    "content": "Error writing data to S3 for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:29:24",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:29:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:29:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:30:00",
    "source": "IngestionService",
    "content": "S3 write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:30:12",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:30:24",
    "source": "IngestionService",
    "content": "S3 write for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:30:36",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:30:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:31:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:31:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:31:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:31:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:31:48",
    "source": "IngestionService",
    "content": "S3 write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:32:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:32:12",
    "source": "IngestionService",
    "content": "S3 write for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:32:24",
    "source": "IngestionService",
    "content": "S3 write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:32:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:32:48",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:33:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:33:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:33:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:33:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:33:48",
    "source": "IngestionService",
    "content": "S3 write for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:34:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:34:12",
    "source": "IngestionService",
    "content": "S3 write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:34:24",
    "source": "IngestionService",
    "content": "S3 write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:34:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:34:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:35:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:35:12",
    "source": "IngestionService",
    "content": "S3 write for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:35:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:35:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:35:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:36:00",
    "source": "IngestionService",
    "content": "S3 write for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:36:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:36:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:36:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:36:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:37:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:37:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:37:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:37:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:37:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:38:00",
    "source": "IngestionService",
    "content": "S3 write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:38:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:38:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:38:36",
    "source": "IngestionService",
    "content": "S3 write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:38:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:39:00",
    "source": "IngestionService",
    "content": "S3 write for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:39:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:39:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:39:36",
    "source": "IngestionService",
    "content": "S3 write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:39:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:40:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:40:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:40:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:40:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:40:48",
    "source": "IngestionService",
    "content": "S3 write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:41:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:41:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:41:24",
    "source": "IngestionService",
    "content": "S3 write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:41:36",
    "source": "IngestionService",
    "content": "S3 write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:41:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:42:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:42:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:42:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:42:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:42:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:43:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:43:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:43:24",
    "source": "IngestionService",
    "content": "Error writing data to S3 for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:43:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-002"
  },
  {
    "timestamp": "2023-12-10T17:43:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:44:00",
    "source": "IngestionService",
    "content": "S3 write for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:44:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:44:24",
    "source": "IngestionService",
    "content": "S3 write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:44:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:44:48",
    "source": "IngestionService",
    "content": "S3 write for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:45:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:45:12",
    "source": "IngestionService",
    "content": "S3 write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:45:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:45:36",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:45:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:46:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:46:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:46:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:46:36",
    "source": "IngestionService",
    "content": "S3 write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:46:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:47:00",
    "source": "IngestionService",
    "content": "S3 write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:47:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:47:24",
    "source": "IngestionService",
    "content": "S3 write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:47:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:47:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:48:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:48:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:48:24",
    "source": "IngestionService",
    "content": "Aurora write for customer-003"
  },
  {
    "timestamp": "2023-12-10T17:48:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:48:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:49:00",
    "source": "IngestionService",
    "content": "S3 write for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:49:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:49:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:49:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:49:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:50:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:50:12",
    "source": "IngestionService",
    "content": "S3 write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:50:24",
    "source": "IngestionService",
    "content": "S3 write for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:50:36",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:50:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:51:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:51:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:51:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:51:36",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:51:48",
    "source": "IngestionService",
    "content": "S3 write for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:52:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:52:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:52:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:52:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:52:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:53:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:53:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:53:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-016"
  },
  {
    "timestamp": "2023-12-10T17:53:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:53:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-009"
  },
  {
    "timestamp": "2023-12-10T17:54:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:54:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:54:24",
    "source": "IngestionService",
    "content": "S3 write for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:54:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:54:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:55:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:55:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-012"
  },
  {
    "timestamp": "2023-12-10T17:55:24",
    "source": "IngestionService",
    "content": "S3 write for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:55:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:55:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-011"
  },
  {
    "timestamp": "2023-12-10T17:56:00",
    "source": "IngestionService",
    "content": "S3 write for customer-005"
  },
  {
    "timestamp": "2023-12-10T17:56:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:56:24",
    "source": "IngestionService",
    "content": "S3 write for customer-007"
  },
  {
    "timestamp": "2023-12-10T17:56:36",
    "source": "IngestionService",
    "content": "Kafka read for customer-006"
  },
  {
    "timestamp": "2023-12-10T17:56:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-014"
  },
  {
    "timestamp": "2023-12-10T17:57:00",
    "source": "IngestionService",
    "content": "S3 write for customer-017"
  },
  {
    "timestamp": "2023-12-10T17:57:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-015"
  },
  {
    "timestamp": "2023-12-10T17:57:24",
    "source": "IngestionService",
    "content": "Kafka read for customer-013"
  },
  {
    "timestamp": "2023-12-10T17:57:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-008"
  },
  {
    "timestamp": "2023-12-10T17:57:48",
    "source": "IngestionService",
    "content": "S3 write for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:58:00",
    "source": "IngestionService",
    "content": "Kafka read for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:58:12",
    "source": "IngestionService",
    "content": "Aurora write for customer-001"
  },
  {
    "timestamp": "2023-12-10T17:58:24",
    "source": "IngestionService",
    "content": "S3 write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:58:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:58:48",
    "source": "IngestionService",
    "content": "Kafka read for customer-010"
  },
  {
    "timestamp": "2023-12-10T17:59:00",
    "source": "IngestionService",
    "content": "Aurora write for customer-004"
  },
  {
    "timestamp": "2023-12-10T17:59:12",
    "source": "IngestionService",
    "content": "Kafka read for customer-019"
  },
  {
    "timestamp": "2023-12-10T17:59:24",
    "source": "IngestionService",
    "content": "S3 write for customer-000"
  },
  {
    "timestamp": "2023-12-10T17:59:36",
    "source": "IngestionService",
    "content": "Aurora write for customer-018"
  },
  {
    "timestamp": "2023-12-10T17:59:48",
    "source": "IngestionService",
    "content": "Aurora write for customer-016"
  }
]