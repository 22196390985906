import React from 'react';
import { Typography } from '@mui/material';
import { Button } from 'react-bootstrap';

export const CodeFixTopBar = ({ onToggleExplorer, filePath }) => {
  return (
    <div style={{ 
      position: 'sticky', // or 'fixed'
      top: 0,
      width: '100%',
      backgroundColor: 'transparent',
      height: '50px',
      zIndex: 1000, // Ensure it stays on top of other elements
      display: 'flex',
      justifyContent: 'space-between', // This will push the Save button to the right
      alignItems: 'center',
      padding: '0 1px',
      boxSizing: 'border-box'
    }}>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={onToggleExplorer} variant='link' style={{ outline: 'none', color: 'gray', marginRight: '10px', width: '10px' }}>
          <i className='mdi mdi-menu'></i>
        </Button>
        <Typography variant="h7" style={{ marginLeft: '10px' }}>
          {filePath}
        </Typography>
      </div>
      <Button color="inherit" variant='link' style={{outline: 'none'}}>Save</Button>
    



    </div>
  );
};

