// CodeFixMain
import React, { useState } from 'react';
import FileEditorWithExplorer from './FileEditorWithExplorer';
import { CodeFixTopBar } from './CodeFixTopBar';

const fileData = {
  id: 'root',
  name: 'Root',
  children: [
      {
          id: '1',
          name: 'Folder 1',
          children: [
              { id: '2', name: 'File 1' },
              { id: '3', name: 'File 2' }
          ],
      },
      {
          id: '4',
          name: 'Folder 2',
          children: [
              {
                  id: '5',
                  name: 'Subfolder 1',
                  children: [
                      { id: '6', name: 'File 3' },
                      { id: '7', name: 'File 4' }
                  ],
              },
          ],
      },
  ],
};

export const CodeFixMain = () => {
  const [explorerOpen, setExplorerOpen] = useState(true);
  const [filePath, setFilePath] = useState('path/to/your/file.js');
  const [fileContent, setFileContent] = useState('// file content here');

  const handleFileChange = (newContent) => {
    setFileContent(newContent);
  };

  const handleToggleExplorer = () => {
    console.log('Handle Toggle for explorer');
    setExplorerOpen(!explorerOpen);
  };

  return (
    <div>
      <CodeFixTopBar onToggleExplorer={handleToggleExplorer} filePath={filePath} />
      <FileEditorWithExplorer
        fileData={fileData}
        selectedFileContent={fileContent}
        onFileChange={handleFileChange}
        explorerOpen={explorerOpen}
      />
    </div>
  );
};
