// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { CommandSequenceActionTypes } from "./constants";
import {
  getCommandSequenceList,
  getCommandSequenceListApiResponseSuccess,
  updateApiResponse,
  updateCommandSequence
} from "./actions";

import { deleteCommandSequenceApi, getCommandSequence, updateCommandSequenceApi
} from "../../helpers/";

function* getCommandSequenceListSaga(action) {
  try {
    const payload = {...action.payload};    
    const response = yield call(getCommandSequence, payload);

    const data = response.data;    
    yield put(getCommandSequenceListApiResponseSuccess(data?.data || []));
  } catch (error) {
    console.log(error);
    yield put(getCommandSequenceListApiResponseSuccess([]));
  }
}

/**
 * Delete Command Sequence
 *
 */
function* deleteCommandSequence(action) {
  try {
    const response = yield call(deleteCommandSequenceApi, action.payload);
    yield put(getCommandSequenceList(action.payload));
    if (response?.data?.errorCode !== "kNoError") {
      yield put(updateApiResponse({ result: "error", msg: "Failed to delete command sequence." }));  
    } else {
      yield put(updateApiResponse({ result: "success", msg: "Command sequence successfully deleted." }));
    }
    
  } catch (error) {
    yield put(updateApiResponse({ result: "error", msg: "Failed to delete command sequence." }));
  }
}

function* updateCommandSequenceRecord(action) {
  try {
    console.log("Payload: ", action);
    const response = yield call(updateCommandSequenceApi, action.payload);
    yield put(getCommandSequenceList(action.payload));
    // if (response?.data?.errorCode !== "kNoError") {
    //   yield put(updateApiResponse({ result: "error", msg: "Failed to delete command sequence." }));  
    // } else {
    //   yield put(updateApiResponse({ result: "success", msg: "Command sequence successfully deleted." }));
    // }
    
  } catch (error) {
    yield put(updateApiResponse({ result: "error", msg: "Failed to delete command sequence." }));
  }
}

export function* watchGetCommandSequenceList() {
  yield takeEvery(CommandSequenceActionTypes.GET_COMMAND_SEQUENCE, getCommandSequenceListSaga);
}

export function* watchDeleteResource() {
  yield takeEvery(CommandSequenceActionTypes.DELETE_COMMAND_SEQUENCE, deleteCommandSequence);
}

export function* watchUpdateResource() {
  yield takeEvery(CommandSequenceActionTypes.UPDATE_COMMAND_SEQUENCE, updateCommandSequenceRecord);
}

function* commandSequenceSaga() {
  yield all([
    fork(watchGetCommandSequenceList),
    fork(watchDeleteResource),
    fork(watchUpdateResource),
  ]);
}

export default commandSequenceSaga;
