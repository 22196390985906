import { ResourceActionTypes } from "./constants";

// actions.js

export const getResourceList = (params) => ({
  type: ResourceActionTypes.GET_RESOURCES,
  payload: params,
});

export const getResourceListApiResponseSuccess = (data) => ({
  type: ResourceActionTypes.GET_RESOURCES_API_SUCCESS,
  payload: data,
});


export const saveResource = (params) => ({
  type: ResourceActionTypes.SAVE_RESOURCES,
  payload: params,
});

export const saveResourceApiResponseSuccess = (data) => ({
  type: ResourceActionTypes.SAVE_RESOURCES_API_SUCCESS,
  payload: data,
});

export const getResourceById = (params) => ({
  type: ResourceActionTypes.GET_RESOURCE_BY_ID,
  payload: params,
});

export const getResourceByIdApiSuccess = (params) => ({
  type: ResourceActionTypes.GET_RESOURCE_BY_ID_API_SUCCESS,
  payload: params,
});

export const deleteResource = (params) => ({
  type: ResourceActionTypes.DELETE_RESOURCE,
  payload: params,
});

export const updateResource = (params) => ({
  type: ResourceActionTypes.UPDATE_RESOURCE,
  payload: params,
});

export const fetchJiraProjects = () => ({
  type: ResourceActionTypes.GET_JIRA_PROJECTS,
  payload: {}
});

export const fetchJiraIssues = (payload) => ({
  type: ResourceActionTypes.GET_JIRA_ISSUES,
  payload
});

export const jiraProjectsApiResponseSuccess = (data) => ({
  type: ResourceActionTypes.GET_JIRA_PROJECTS_SUCCESS,
  payload: data,
});

export const jiraIssuesApiResponseSuccess = (data) => ({
  type: ResourceActionTypes.GET_JIRA_ISSUES_SUCCESS,
  payload: data,
});

