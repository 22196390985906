// @flow
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Routes from "./routes/Routes";
import config from "./config";

// setup fake backend
import { configureFakeBackend } from "./helpers";

// Themes

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";
import SplashScreen from "./pages/rptless/account/SplashScreen";
import { useDispatch } from "react-redux";
import { getUser } from "./redux/actions";
import { setAuthToken } from "./helpers/api/apiCore";

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';

// configure fake backend
configureFakeBackend();

type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
  const {
    isLoading,
    error,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
        dispatch(getUser(user?.email));
      } catch (e) {
        if (
          e.error === "missing_refresh_token" ||
          e.error === "invalid_grant"
        ) {
          loginWithRedirect();
        }
      }
    };
    if (isAuthenticated) {      
      fetchToken();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (config.AUTH0_ENABLED && error && !isAuthenticated) {
    return <div>Oops... {error.message}</div>;
  }

  if (config.AUTH0_ENABLED && isLoading) {
    return <SplashScreen />;
  }
  return <Routes></Routes>;
};

export default App;
