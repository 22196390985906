import { Button, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import { InlineEdit } from "./EditableLabel";
import { useState } from "react";
import ShareResultModal from "../trbshell/commandblock/ShareResultModal";

export const PageTitle = (props) => {
  const { trsId } = props;
  const [shareToggle, setShareToggle] = useState(false);
  const handleShareClick = () => setShareToggle(!shareToggle);
  return (
    <Row className="d-flex align-items-center">
      <Col sm={9}>
        <div className="page-title-box">
          <div className="page-title-right"></div>

          <p className="d-inline-block mt-3 mb-2">
            <span>
              {" "}
              <InlineEdit
                value={props.title}
                setValue={(v) => props.onChange(v)}
              />{" "}
            </span>
            {/* <span className="mb-2" style={{fontSize:'20px', fontFamily:'"Nunito", sans-serif', color: '#303030', paddingLeft:'7px'}}>{props.title}</span> */}
            &nbsp; &nbsp;
            {/* <i className="mdi mdi-square-edit-outline"></i> */}
            {/* <div>
              <small style={{ marginLeft: "10px" }}>
                Last updated: 5/24/2023 11:51 am
              </small>
            </div> */}
          </p>
        </div>
        <div></div>
      </Col>
      <Col sm={3} className="d-flex align-items-center justify-content-end">
        <ButtonGroup as={Dropdown} className="d-inline-block">
          <Dropdown.Toggle variant="light" className="arrow-none">
            <i className="mdi mdi-folder-outline"></i>
            &nbsp; Personal
            <i className="mdi mdi-chevron-down"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <span className="dropdown-header">Move to:</span>
            <Dropdown.Item>Team: DataPipeline</Dropdown.Item>
            <Dropdown.Item>Team: Cloud Infra</Dropdown.Item>
            <Dropdown.Item>Team: MLOps</Dropdown.Item>
            <Dropdown.Item style={{ borderTop: "1px solid #dedede" }}>
              Organize...
            </Dropdown.Item>
          </Dropdown.Menu>
          <Button variant="light" className="ms-2" onClick={handleShareClick}>
            <i className="mdi mdi-share-variant-outline me-1"></i> Share
          </Button>
        </ButtonGroup>
      </Col>
      {shareToggle && (
        <ShareResultModal
          shareToggle={shareToggle}
          onCancel={handleShareClick}
          onSave={handleShareClick}
          trsId={trsId}
        />
      )}
    </Row>
  );
};
