import React, { useState } from 'react';
import { Discussion, Jira, Recent, Vars } from './Common';
import { Button, Card } from 'react-bootstrap';
import { Assistant } from './Assistant';
import { conversationList } from '../mockData';
// Import the content components

const SidePanel = () => {
  const [selectedContent, setSelectedContent] = useState('Recent');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleButtonClick = (content) => {
    if (selectedContent === content && !isCollapsed) {
      // Collapse the panel if the same button is clicked
      setIsCollapsed(true);
    } else {
      setSelectedContent(content);
      setIsCollapsed(false); // Expand the panel if a different button is clicked
    }
  };

  const getContentComponent = () => {
    // if (isCollapsed) return null; // Don't render content if panel is collapsed

    switch (selectedContent) {
      case 'Recent':
        return <Recent />;
      case 'Vars':
        return <Vars />;
      case 'Assistant':
        return <Assistant conversationList={conversationList} conversation={conversationList[0]} />;
      case 'Jira':
        return <Jira />;
      case 'Discussion':
        return <Discussion />;
      default: return <div>No content selected</div>;
    }
  };

  return (
    <div style={{
      marginLeft: '1px',
      display: 'flex',
      width: isCollapsed ? '45px' : '42%',
      transition: 'width 0.1s ease-out'
    }}>
      <div style={{
        flex: 1,
        width: isCollapsed ? '0' : 'auto',
        overflow: 'hidden',
        transition: 'width 0.1s ease-out',
        // borderLeft: '1px solid rgba(114,124, 245, 0.3)',  // Purple
      }}>
          <div style={{
            // border: '1px solid rgba(240, 240, 240, 0.6)'
          }}>
            <Card style={{ minHeight: '600px', borderRadius: '5px' }}>{getContentComponent()}</Card>
          </div>
      </div>
      {/* <div style={{
        width: '50px',
        display: 'flex',
        flexDirection: 'column'
      }}> */}
      <div
        style={{
          width: '45px',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Button
          variant={`outline-${
            selectedContent === "Recent" ? "primary" : "secondary"
          }`}
          size="sm"
          className={`p-1 ${selectedContent === "Recent" ? "border border-1 border-primary font-18" : ""}`}
          onClick={() => handleButtonClick("Recent")}
        >
          {" "}
          <i className="mdi mdi-history"></i>
        </Button>
        <Button
          variant={`outline-${
            selectedContent === "Vars" ? "primary" : "secondary"
          }`}
          size="sm"
          className={`p-1 ${selectedContent === "Vars" ? "border border-1 border-primary font-18" : ""}`}
          onClick={() => handleButtonClick("Vars")}
        >
          <i className="mdi mdi-application-array-outline"></i>
        </Button>
        <Button
          variant={`outline-${
            selectedContent === "Assistant" ? "primary" : "secondary"
          }`}
          size="sm"
          className={`p-1 ${selectedContent === "Assistant" ? "border border-1 border-primary font-18" : ""}`}
          onClick={() => handleButtonClick("Assistant")}
        >
          <i className="mdi mdi-robot-outline"></i>
        </Button>
        <Button
          variant={`outline-${
            selectedContent === "Jira" ? "primary" : "secondary"
          }`}
          size="sm"
          className={`p-1 ${selectedContent === "Jira" ? "border border-1 border-primary font-18" : ""}`}
          onClick={() => handleButtonClick("Jira")}
        >
          <i className="mdi mdi-bug-outline"></i>
        </Button>
        <Button
          variant={`outline-${
            selectedContent === "Discussion" ? "primary" : "secondary"
          }`}
          size="sm"
          className={`p-1 ${selectedContent === "Discussion" ? "border border-1 border-primary font-18" : ""}`}
          onClick={() => handleButtonClick("Discussion")}
        >
          <i className="mdi mdi-chat-outline"></i>
        </Button>
        
      </div>
    </div>
  );
};

export default SidePanel;
