// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { format } from 'date-fns';
import { UserActivityActionTypes } from "./constants";
import {
  getAllUserListApiResponseSuccess,
  getUserActivityListApiResponseSuccess,
} from "./actions";

import { getUserActivity, getAllUsers
} from "../../helpers/";

function* getUserActivityList(action) {
  try {
    const payload = {...action.payload};
    if (payload?.users?.length === 0) {
      delete payload.users;
    }
    if (payload?.activityTypes?.length === 0) {
      delete payload.activityTypes;
    }

    if (payload.startDate) {
      payload.startDate = format(payload.startDate, "yyyy-MM-dd");
    }

    if (payload.endDate) {
      payload.endDate = format(payload.endDate, "yyyy-MM-dd");
    }
    
    const response = yield call(getUserActivity, payload);

    const data = response.data;
    yield put(getUserActivityListApiResponseSuccess(data?.data || []));
  } catch (error) {
    console.log(error);
    yield put(getUserActivityListApiResponseSuccess([]));
  }
}

function* getAllUserList(action) {
  try {

    const response = yield call(getAllUsers, action.payload);
    const data = response.data;
    yield put(getAllUserListApiResponseSuccess(data || []));
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetUserActivityList() {
  yield takeEvery(UserActivityActionTypes.GET_USER_ACTIVITY, getUserActivityList);
}

export function* watchGetAllUserList() {
  yield takeEvery(UserActivityActionTypes.GET_ALL_USER, getAllUserList);
}


function* userActivitySaga() {
  yield all([
    fork(watchGetUserActivityList),
    fork(watchGetAllUserList),
  ]);
}

export default userActivitySaga;
