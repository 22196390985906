import { styled } from 'styled-components';
import React from 'react';


// Styled components
const VerticalLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  margin-left: 40px;
`;

const Box = styled.div`
  width: 400px;
  height: auto;
  background-color: rgba(69, 77, 88, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const VerticalLine = styled.div`
  margin-left: 10px;
  border-left: 1px solid rgba(69, 77, 88, 0.1);
  height: 50px; /* Adjust height as needed */
`;

const Content = () => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div>
      <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => setExpanded(!expanded)}>
        Node 1
      </div>
      {expanded && <div>Description for Node 1</div>}
    </div>
  );
}

const Suggestions = () => {
  return (
    <VerticalLineContainer>
      <Box>
        <p>Div 1</p>
        <Content />
      </Box>
      <div>
       <VerticalLine /> 
      </div>
      <Box>
        <p>Div 2</p>
      </Box>
      <VerticalLine />
      <Box>
        <p>Div 3</p>
      </Box>
    </VerticalLineContainer>
  );
};

export default Suggestions;
