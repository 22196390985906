import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import React from "react";
import ReactJson from "react-json-view";
import { LazyLog } from "react-lazylog";
import { useState } from "react";
import ChartView from "./ChartView";
import AddVariableCmp from "../trbshell/commandblock/subcomponents/AddVariableCmp";
import AccessRestricted from "../common/AccessRestricted"
import { sampleCode } from "../data/DemoCode";

const someCode = `
export const CodeViewer = ({codeString}) => {
  return (
    <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
      <code>
        {codeString}
      </code>
    </pre>
  );
}
`;

export const CodeViewer = ({codeString}) => {
  return (
    <pre  style={{
      backgroundColor: "#f5f5f5", 
      padding: "10px", 
      borderRadius: "5px",
      maxHeight: "400px",
      overflowY: "scroll"
    }}>
      <code>
        {sampleCode}
      </code>
    </pre>
  );
}

export const TableView = (props) => {
  // props.dataTable contains the JSONified proto common.proto/DataTable
  const [mode, setMode] = useState("Table");
  const dt = props.dataTable;
  const modes = ["Table", "Chart"];

  const changeMode = (mode) => setMode(mode);

  const buildTableRow = (row, colTypes) => {
    return (
      <tr>
        {row.data.map((vObj, index) => {
          switch (colTypes[index]) {
            case "kDateString":
              return (
                <td key={`table-cell-${colTypes}-${index}`}> {vObj.val} </td>
              );

            case "kInt64":
              return <td> {vObj.int64_val} </td>;

            case "kString":
              return <td> {vObj.val} </td>;

            default:
              return <td>NotSupported</td>;
          }
        })}
      </tr>
    );
  };

  return (
    <div className="command-result-details">
     {/* <AccessRestricted
        message="You do not have permission to access this content."
        isOpen
      /> */}
      <Row className="mb-2 d-flex align-items-center">
        <Col className="col-8">
          <div className="fw-bold">Last run result:</div>
        </Col>
        <Col className="col-4 d-flex justify-content-end">
          <AddVariableCmp handleVarSubmit={props.handleVarSubmit} />
          <ButtonGroup className="ms-1">
            {modes.map((m, idx) => {
              return (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">{`${m} View`}</Tooltip>
                  }
                  key={idx}
                >
                  <Button
                    variant={mode === m ? "primary" : "light"}
                    size={"sm"}
                    key={idx}
                    onClick={() => changeMode(m)}
                    active={mode === m}
                  >
                    <i
                      className={m === "Table" ? "uil-table" : "uil-chart"}
                    ></i>
                  </Button>
                </OverlayTrigger>
              );
            })}
          </ButtonGroup>
        </Col>
      </Row>

      {mode === "Table" && (
        <Table className="mb-0" size="sm" bordered striped>
          <thead>
            <tr>
              {dt.colName.map((colHeading, index) => (
                <th key={`table-heading-${index}`}> {colHeading} </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dt.rows.map((r, index) => buildTableRow(r, dt.colTypes))}
          </tbody>
        </Table>
      )}
      {mode === "Chart" && <ChartView data={dt} />}
    </div>
  );
};

export const SimpleStrView = (props) => {
  return (
    <div className="command-result-details">
      {/* Put JSON view here */}
      {props.strVal}
    </div>
  );
};

export const JsonView = (props) => {
  return (
    <>
      <Row>
        <Col className="col-12 d-flex justify-content-end command-result-border">
          <AddVariableCmp handleVarSubmit={props.handleVarSubmit} />
        </Col>
      </Row>
      <div className="command-result-details">
        {/* Put JSON view here */}
        <ReactJson
          src={JSON.parse(props.jsonStr)}
          name={false}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </div>
    </>
  );
};

export const LogView = (props) => {
  return (
    <div style={{ height: 500, width: 700 }}>
      <LazyLog
        extraLines={1}
        enableSearch
        text={props.text}
        caseInsensitive
        style={{ backgroundColor: "silver" }}
        containerStyle={{ backgroundColor: "white", color: "black" }}
      />
    </div>
  );
};
