import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Dropdown, Row} from 'react-bootstrap';
import LogViewer from '../../resultviews/LogViewer';
import { sampleLogs } from '../../data/LogData';
import TrShellMain from './TrShellMain';
import { CodeFixMain } from '../codefixer/CodeFixMain';
import { CloudShell } from '../cloudshell/CloudShell';

let tabIdCounter = 0; // Counter to generate unique IDs for tabs

const Tab = ({ title, content }) => {
  if (title == 'TrShell #89af') {
    return (
      <>
        {/* <div style={{marginLeft:'-5px'}}>a</div> */}
        <TrShellMain docId={'1'} />
      </>
    )
  }

  if (title == 'Code Editor') {
    return (<CodeFixMain />);
  }

  if (title == 'Cloudshell') {
    return (<CloudShell />);
  }

  return (<>
    {/* <div style={{width: 'auto', flex: '0 1 auto', display: 'block'}}> */}
    <div className='ms-3 mt-3 pe-5'>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button variant="primary" size='sm' style={{ width: 'auto', display: 'inline-block' }} >
          <span>
            <i className="mdi mdi-plus"></i>
            &nbsp; Command
          </span>
        </Button>
        &nbsp; &nbsp;
        <ButtonGroup as={Dropdown} className="d-inline-block">
          <Dropdown.Toggle variant="light" className="arrow-none">
            {/* <i className="mdi mdi-folder-outline"></i> */}
            Actions &nbsp;
            <i className="mdi mdi-chevron-down"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <span className="dropdown-header">Move to:</span>
            <Dropdown.Item>Team: DataPipeline</Dropdown.Item>
            <Dropdown.Item>Team: Cloud Infra</Dropdown.Item>
            <Dropdown.Item>Team: MLOps</Dropdown.Item>
            <Dropdown.Item style={{ borderTop: "1px solid #dedede" }}>
              Organize...
            </Dropdown.Item>
          </Dropdown.Menu>
        </ButtonGroup>

      </div>

      <Row>
        <div>
          <h2>{title}</h2>
          <p>{content}</p>
        </div>
        <div>
          <LogViewer logs={sampleLogs} />
        </div>

      </Row>

      <Row>
      </Row>

    </div>
  </>);
};

const TabTitleWithClose = ({ title, onTabClick, onCloseClick, isActive }) => {
  const tabStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Align items on opposite ends
    width: '130px',
    fontSize: '14px', //'0.8em',
    fontWeight: '500',
    marginRight: '3px',
    padding: '8px 8px',
    cursor: 'pointer',
    borderBottom: isActive
      ? "2px solid #fff"
      : "1px solid #dee2e6",  
    borderRight: isActive
      ? "1px solid #dee2e6"
      : "1px solid rgba(170,170,170,0.4)",
    // borderBottom: isActive ? '2px solid #fff' : '1px solid rgba(170,170,170,0.4)',
    // borderRight: isActive ? '1px solid #aaa' : '1px solid rgba(170,170,170,0.4)',
    // borderTop: isActive ? '1px solid #aaa' : '1px solid rgba(170,170,170,0.4)',
    backgroundColor: isActive ? '#fff' : '',
    zIndex: 1,
    position: 'relative'
  };

  return (
    <div style={tabStyle} className={isActive ? "text-primary" : ""}>
      <div onClick={onTabClick}>{title}</div>
      <i onClick={onCloseClick} style={{ marginLeft: '5px' }} className="mdi mdi-close"></i>
    </div>
  );
};

const Workspace = () => {
  const initialTabs = [
    { id: tabIdCounter++, title: 'TrShell #89af', content: 'Content for Tab 1' },
    { id: tabIdCounter++, title: 'Cloudshell', content: 'Content for Tab 1' },
    { id: tabIdCounter++, title: 'Code Editor', content: 'Content for Tab 2' },
  ];

  const [tabs, setTabs] = useState(initialTabs);
  const [activeTabId, setActiveTabId] = useState(initialTabs[0].id);

  const addTab = () => {
    const newTab = {
      id: tabIdCounter++,
      title: `Tab ${tabIdCounter}`,
      content: `Content for Tab ${tabIdCounter}`
    };
    setActiveTabId(newTab.id); // Automatically set new tab as active
    setTabs([...tabs, newTab]);
  };

  const closeTab = (tabId, event) => {
    event.stopPropagation(); // Prevents the tab from becoming active when the close button is clicked
    const newTabs = tabs.filter(tab => tab.id !== tabId);
    setTabs(newTabs);
    if (activeTabId === tabId && newTabs.length > 0) {
      setActiveTabId(newTabs[0].id); // Set the first tab as active
    } else if (newTabs.length === 0) {
      setActiveTabId(null); // No active tab
    }
  };

  console.dir(tabs);
  console.dir("Active tab: " + activeTabId);
  return (
    <Card style={{ flex: 1 }}>
      <Card.Body className="p-0">
      {/* Workspace */}
      <div style={{
          display: "flex",
          position: "relative",            
        }}
      >
        {/* <div style={{ display: 'flex', position: 'relative', boxShadow: 'var(--ct-card-box-shadow)' }}> */}
        {tabs.map(tab => (
          <TabTitleWithClose
            key={tab.id}
            title={tab.title}
            onTabClick={() => setActiveTabId(tab.id)}
            onCloseClick={(e) => closeTab(tab.id, e)}
            isActive={tab.id === activeTabId}
          />
        ))}
        <button onClick={addTab}
          style={{ border: 'none', outline: 'none', background: 'transparent', fontSize: '0.9em', color: 'grey' }}>
          <i
            className="mdi mdi-plus-circle-outline"></i>
        </button>
        <div style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          borderBottom: "1px solid #dee2e6",
          // borderBottom: '1px solid #ccc',
          zIndex: 0 // Ensure the line is below the tabs
        }}></div>
      </div>
      <div>
          <Row>
            {tabs.length > 0 && tabs.find(tab => tab.id === activeTabId) &&
              <Tab
                title={tabs.find(tab => tab.id === activeTabId).title}
                content={tabs.find(tab => tab.id === activeTabId).content}
              />
            }


          </Row>
      </div>
      </Card.Body>
    </Card>
  );
};

export default Workspace;