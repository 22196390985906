import { APICore } from "./apiCore";

const api = new APICore();

// useractivity
function getUserActivity(params) {
  const baseUrl = "/useractivity";
  return api.get(`${baseUrl}`, params);
}

// users
function getAllUsers(params) {
  const baseUrl = "/allusers";
  return api.get(`${baseUrl}?tenantId=${params.tenantId}`);
}


export { getUserActivity, getAllUsers };