import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Overlay, Popover, Row } from "react-bootstrap";
import { FormInput } from "../../../../../components";

const AddVariableCmp = (props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [label, setLabel] = useState("");
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleSave = (event) => {
    props.handleVarSubmit({name : label});
    setShow(!show);    
  };

  return (
    <div ref={ref} className="">
      <Button variant="link" onClick={handleClick} size="sm">
        Add to Vars
      </Button>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <Row>
              <Col lg={12}>
                <FormInput
                  label="Name"
                  type="text"
                  name="text"
                  containerClass={"mb-3"}
                  size="sm"
                  onChange={({ target }) => setLabel(target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="d-flex justify-content-end">
                <Button variant="link" onClick={handleClick} size="sm">
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSave}
                  size="sm"
                  className="ml-1"
                  disabled={label?.trim()?.length === 0}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default AddVariableCmp;
