export const sampleCode = `
const { Kafka } = require('kafkajs');
const AWS = require('aws-sdk');
const moment = require('moment');
const { processBatchData } = require('./processBatchData');

class MetadataWriter {
    constructor(topic) {
        this.topic = topic;
        this.kafka = new Kafka({
            clientId: 'my-app',
            brokers: ['kafka1:9092', 'kafka2:9092'] // Replace with your Kafka brokers
        });
        this.auroraClient = new AWS.RDSDataService(); // Setup AWS RDS Data Service client
        this.s3 = new AWS.S3(); // Setup AWS S3 client
    }

    async start() {
        const consumer = this.kafka.consumer({ groupId: 'my-group' });
        
        await consumer.connect();
        await consumer.subscribe({ topic: this.topic, fromBeginning: true });

        consumer.run({
            eachMessage: async ({ topic, partition, message }) => {
                // Process messages
                const processedData = processBatchData(message);

                // Write to Aurora
                this.writeToAurora(processedData);

                // Write to S3
                const timestamp = moment().format('YYYY-MM-DD:HH:mm');
                this.writeToS3(processedData, timestamp);
            },
        });

        setInterval(async () => {
            // Additional logic if needed for periodic processing
        }, 120000);
    }

    async writeToAurora(data) {
        const { customerId, timestampBucket, ingestionVolume } = data;

        const params = {
            secretArn: 'arn:aws:secretsmanager:region:accountId:secret:secretName',
            resourceArn: 'arn:aws:rds:region:accountId:cluster:clusterIdentifier',
            sql: 'INSERT INTO your_table_name (customer_id, timestamp_bucket, ingestion_volume) VALUES (:customerId, :timestampBucket, :ingestionVolume)',
            database: 'your_database_name',
            parameters: [
                { name: 'customerId', value: { stringValue: customerId } },
                { name: 'timestampBucket', value: { stringValue: timestampBucket } },
                { name: 'ingestionVolume', value: { longValue: ingestionVolume } },
            ],
        };

        try {
            const result = await this.auroraClient.executeStatement(params).promise();
            console.log('Data written to Aurora:', result);
        } catch (error) {
            console.error('Error writing to Aurora:', error);
        }
    }

    async writeToS3(data, timestamp) {
        const params = {
            Bucket: 'your-bucket-name',
            Key: \`data/\${timestamp}/data.json\`,
            Body: JSON.stringify(data)
        };

        try {
            await this.s3.putObject(params).promise();
            console.log('Data written to S3 successfully');
        } catch (error) {
            console.error('Error writing to S3:', error);
        }
    }
}

module.exports = MetadataWriter;

`;