import { CommandSequenceActionTypes } from "./constants";

// actions.js

export const getCommandSequenceList = (params) => ({
  type: CommandSequenceActionTypes.GET_COMMAND_SEQUENCE,
  payload: params,
});

export const getCommandSequenceListApiResponseSuccess = (data) => ({
  type: CommandSequenceActionTypes.GET_COMMAND_SEQUENCE_API_SUCCESS,
  payload: data,
});

export const deleteCommandSequenceAction = (params) => ({
  type: CommandSequenceActionTypes.DELETE_COMMAND_SEQUENCE,
  payload: params,
});

export const updateApiResponse = (data) => ({
  type: CommandSequenceActionTypes.UPDATE_COMMAND_SEQUENCE_API_RESULT,
  payload: data,
});

export const updateCommandSequence = (params) => ({
  type: CommandSequenceActionTypes.UPDATE_COMMAND_SEQUENCE,
  payload: params,
});

