import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import config from "../config";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      {...rest}
      render={(props) => {        
        if (config.AUTH0_ENABLED && !isAuthenticated) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
