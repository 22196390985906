import { APICore } from "./apiCore";

const api = new APICore();

// trs
function getUserGroups(params) {
  const baseUrl = "/usersandgroups";
  return api.get(`${baseUrl}`, params);
}

// trs access save
function shareTrs(params) {
  const baseUrl = "/shareTrs";
  return api.create(`${baseUrl}`, params);
}

// get Jira Projects
function getJiraProjectsApi(params) {
  const baseUrl = "/jira-projects";
  return api.get(`${baseUrl}`, params);
}

// get Jira Issues
function getJiraIssuesApi(params) {
  const baseUrl = "/jira-issues";
  return api.get(`${baseUrl}`, params);
}

// get Jira Issues
function createPlaybookApi(params) {
  const baseUrl = "/create-playbook";
  return api.create(`${baseUrl}`, params);
}

export { createPlaybookApi, getJiraIssuesApi, getJiraProjectsApi, getUserGroups, shareTrs };
