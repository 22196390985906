// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import trsSaga from './trs/saga';
import settingsSaga from './settings/saga';
import assistantChatSaga from './assistantchat/saga';
import resourceSaga from './resources/saga';
import sdocsSaga from './ctx/saga';
import ctxSaga from './ctx/saga';
import userActivitySaga from './user/saga';
import commandSequenceSaga from './commandSequence/saga';

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), resourceSaga(), settingsSaga(), trsSaga(), assistantChatSaga(), sdocsSaga(), ctxSaga(), userActivitySaga(), commandSequenceSaga()]);
}
