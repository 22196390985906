import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = (props): React$Element<any> => {
  const { data, xaxis, yaxis } = props;

  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);  

  useEffect(() => {
    const xaxisIndex = data.colName.indexOf(xaxis);
    const yaxisIndex = data.colName.indexOf(yaxis);

    const labelData = data.rows.map(
      (item) => Object.values(item.data[xaxisIndex])[0]
    );
    const chartYData = data.rows.map(
      (item) => Object.values(item.data[yaxisIndex])[0]
    );

    setLabels(labelData);
    setChartData(chartYData);    
  }, [data, xaxis, yaxis]);

  // chart data
  const lineChartData = {
    labels,
    datasets: [
      {
        label: yaxis,
        backgroundColor: "rgba(114, 124, 245, 0.3)",
        borderColor: "#727cf5",
        data: chartData,
        tension: 0.4,
        fill: {
          target: "origin",
          above: "rgba(114, 124, 245,0.3)",
        },
      },
    ],
  };

  // default options
  const lineChartOpts = {
    bezierCurve: false,
    maintainAspectRatio: false,
    plugins: {
      filler: {
        propagate: false,
      },
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: () => "rgba(0,0,0,0.05)",
        },
      },
      y: {
        ticks: {
          stepSize: 20,
        },
        display: true,
        borderDash: [5, 5],
        grid: {
          color: () => "rgba(0,0,0,0)",
          fontColor: "#fff",
        },
      },
    },
  };

  return (
    <div style={{ height: "320px" }} className="mt-3 chartjs-chart">
      <Line data={lineChartData} options={lineChartOpts} />
    </div>
  );
};

export default LineChart;
