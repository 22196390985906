import { CommandSequenceActionTypes } from "./constants";

const initialState = {
  commandSequenceList: [],
  apiResult: null,
  msg: ""
};

const CommandSequence = (state = initialState, action) => {
  switch (action.type) {

    case CommandSequenceActionTypes.GET_COMMAND_SEQUENCE_API_SUCCESS: {
      return {
        ...state,
        commandSequenceList: action.payload,
      };
    }
    case CommandSequenceActionTypes.UPDATE_COMMAND_SEQUENCE_API_RESULT: {
      return {
        ...state,
        apiResult: action.payload.result,
        msg: action.payload.msg
      };
    }
    case CommandSequenceActionTypes.UPDATE_COMMAND_SEQUENCE: {
      return {
        ...state,
        commandSequenceList: action.payload,
      }
    }
    default:
      return state;
  }
};

export default CommandSequence;