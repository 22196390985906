import { Row } from "react-bootstrap";
import { PageTitle } from "../utils/PageTitle";
import Workspace from "./mainpanel/Workspace";
import SidePanel from "./sidepanel/SidePanel";

const CtxLoader = ({wsDocId}) => {
  // if wsDocId is null then create a new workspace.
  // Else load the wsDocId

  // Update redux with the wsDoc.
}

export const CtxMain = ({wsDocId}) => {

  
  return (<>
    <Row className="mt-2">
      <Workspace />
      <SidePanel />
    </Row>
  </>
  );
};