export const SettingsActionTypes = {
  UPDATE_PROFILE: "UPDATE_PROFILE",
  GET_HOSTS: "GET_HOSTS",
  GET_HOSTS_API_SUCCESS: "GET_HOSTS_API_SUCCESS",
  DELETE_HOST: "DELETE_HOST",
  SAVE_HOST: "SAVE_HOST",
  SAVE_HOST_API_SUCCESS: "SAVE_HOST_API_SUCCESS",
  UPDATE_HOST: "UPDATE_HOST",
  GET_ACCOUNTS: "GET_ACCOUNTS",
  GET_ACCOUNTS_API_SUCCESS: "GET_ACCOUNTS_API_SUCCESS",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  SAVE_ACCOUNT: "SAVE_ACCOUNT",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  GET_ORGS: "GET_ORGS",
  GET_ORGS_API_SUCCESS: "GET_ORGS_API_SUCCESS",
  DELETE_ORG: "DELETE_ORG",
  SAVE_ORG: "SAVE_ORG",
  UPDATE_ORG: "UPDATE_ORG",
  INVITE_MEMBER: "INVITE_MEMBER",
  GET_ORG_MEMBER: "GET_ORG_MEMBER",
  GET_ORG_MEMBER_API_SUCCESS: "GET_ORG_MEMBER_API_SUCCESS",
  GET_ORG_INVITES: "GET_ORG_INVITES",
  GET_ORG_INVITES_API_SUCCESS: "GET_ORG_INVITES_API_SUCCESS",
  REMOVE_ORG_MEMBER: "REMOVE_ORG_MEMBER",
  ACCEPT_ORG_INVITATION: "ACCEPT_ORG_INVITATION",
  LEAVE_ORG: "LEAVE_ORG",
  GET_ASSISTANT_CONTEXTS: "GET_ASSISTANT_CONTEXTS",
  GET_ASSISTANT_CONTEXTS_API_SUCCESS: "GET_ASSISTANT_CONTEXTS_API_SUCCESS",
  DELETE_ASSISTANT_CONTEXT: "DELETE_ASSISTANT_CONTEXT",
  SAVE_ASSISTANT_CONTEXT: "SAVE_ASSISTANT_CONTEXT",  
  UPDATE_ASSISTANT_CONTEXT: "UPDATE_ASSISTANT_CONTEXT",
};
