import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import configJson from "./auth_config.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: configJson.domain,
  clientId: configJson.clientId,
  useRefreshTokens: true,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: configJson.audience
  },
};

ReactDOM.render(
  <Provider store={configureStore({})}>
    <Auth0Provider {...providerConfig}>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);
