import React, { memo, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Handle, Position } from "reactflow";

export default memo(({ id, data, isConnectable }) => {
  const [text, setText] = useState("resource description...");
  const [isEditing, setIsEditing] = useState(false);

  const handleTextChange = (event) => {
    setText(event.target.value); // Limit text length to 100 characters
    const newText = {...data.nodeDesc};
    newText[id] = event.target.value;
    data.setNodeDesc(newText);
  };

  const handleFocusOut = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of submitting the form
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <div className="custom-node__header">
        <strong>Resource #{id}</strong>
      </div>
      <div className="custom-node__body">
        <div className="pb-1 d-flex align-items-center">
          {isEditing ? (
            <InputGroup>
              <FormControl
                autoFocus
                type="text"
                value={text}
                onChange={handleTextChange}
                onBlur={handleFocusOut}
                onKeyDown={handleKeyDown}
                className="w-75"
              />
            </InputGroup>
          ) : (
            <div onClick={handleEditClick}>
              {text?.slice(0, 100)}
              <i className="mdi mdi-circle-edit-outline" />
            </div>
          )}
        </div>
        <div className="w-100" style={{ backgroundColor: "#f1f3fa"}}>
          <code>{data?.commandDetails?.mainText}</code>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id={id}
        isConnectable={isConnectable}
      />
    </>
  );
});
