import TextareaAutosize from 'react-textarea-autosize';
import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { resourceNames } from '../../data/constants';
import { iconClassFromResourceType } from '../utils';

const mentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontFamily: '"Fira Code", monospace',
    fontSize: 14,
    border: '0px solid white',
    // fontWeight: 'normal',
  },
  '&multiLine': {
    control: {
      fontFamily: '"Fira Code", monospace',
      minHeight: 40,
      border: '0px solid white',
    },
    highlighter: {
      padding: 9,
      // border: '1px solid transparent',
      border: '0px solid white',
    },
    input: {
      padding: 9,
      border: '0px solid silver',
    },
  },
  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.05)',
      fontSize: 12,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#f1f1f1',
      },
    },
  },
};

const mentionStyles = {
  backgroundColor: 'rgba(114, 124, 245, 0.1)'
};


// Learnt react-mention usage the below from these links -
// https://blog.logrocket.com/build-react-comment-form-mention-functionality/#using-mentions-input-mention-components
// https://medium.com/upshotly/implementing-using-react-mentions-from-react-to-database-and-vice-versa-657c936cfc42
// Examples here -
// https://codesandbox.io/embed/lexical-rich-text-example-26zxky?codemirror=1
// https://codesandbox.io/s/react-mentions-2osgv8
//
// A better component using DraftJS can be built using Decorators as described in this video.
// https://www.youtube.com/watch?v=K9wHt1pIUUI
// For variables handling, this approach can be better in order to color invalid vars as red.
// i.e. apply different coloring to the "mentioned" text.
const MentionsBasedInput = (props) => {
  // const [value, setValue] = useState('');
  const [value, setValue] = useState(props.defaultValue);

  // useEffect(() => {
  //   if (props.defaultValue) {
  //     setValue(props.defaultValue);
  //   }
  // }, [props.defaultValue]);

  const onChange = (e) => {
    setValue(e.target.value)
    props.onChange(e);
  }

  const displayTransform = (id, display) => {
    return `resource=${display}`;
  }

  return (<>
    <MentionsInput
        value={value}
        onChange={(e) => onChange(e)}
        style={mentionsInputStyle}
        className='cmd-main-box-mention fira-code-block'
        spellCheck='false'
    >
      <Mention
        trigger="resource="
        data={resourceNames.map((r) => ({...r, id: r.resourceId, display: r.resourceName}))}
        style={mentionStyles}
        renderSuggestion={(suggestion) => {
          return (<i className={iconClassFromResourceType(suggestion.resourceType)}> {suggestion.display} </i>);
        }}
        appendSpaceOnAdd
        displayTransform={displayTransform}
      />
    </MentionsInput>
    
    
  
  </>
  );
}

export const CmdMainTextBix = (props) => {
  return (<>
    <MentionsBasedInput {...props}/>
    {/* <TextareaAutosize {...props} /> */}
  </>);
}

