// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { ResourceActionTypes } from "./constants";
import {
  getResourceByIdApiSuccess,
  getResourceListApiResponseSuccess,
  saveResourceApiResponseSuccess,
  getResourceList as getResourceListAction,
  jiraProjectsApiResponseSuccess,
  jiraIssuesApiResponseSuccess,
} from "./actions";

import {
  createResource as createResourceApi,
  deleteResource as deleteResourceApi,
  getJiraIssuesApi,
  getJiraProjectsApi,
  getResourceById as getResourceByIdApi,
  getResourceList as getResourceListApi,
  updateResource as updateResourceApi,
} from "../../helpers/";

/**
 * Get the resource list
 *
 */
function* getResourceList(action) {
  try {
    const response = yield call(getResourceListApi, action.payload);
    const data = response.data;
    yield put(getResourceListApiResponseSuccess(data?.data?.resources || []));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Create Resource
 *
 */
function* createResource(action) {
  try {
    const response = yield call(createResourceApi, action.payload);
    const data = response.data;
    yield put(saveResourceApiResponseSuccess(data.data));
    yield put(getResourceList({ userId: action.payload.userId }));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get Resource By ID
 *
 */
function* getResourceById(action) {
  try {
    const response = yield call(getResourceByIdApi, action.payload);
    const data = response.data;
    yield put(getResourceByIdApiSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete Host
 *
 */
function* deleteResource(action) {
  try {
    yield call(deleteResourceApi, action.payload);
    yield put(getResourceListAction(action.payload));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Update Resource
 *
 */
function* updateResourceById(action) {
  try {
    const response = yield call(updateResourceApi, action.payload);
    const data = response.data;
    yield put(getResourceByIdApiSuccess(data.data));    
  } catch (error) {
    console.log(error);
  }
}

function* getJiraProjects(action) {
  try {
    const response = yield call(getJiraProjectsApi, {      
    });
    const projects = response.data;
    yield put(
      jiraProjectsApiResponseSuccess(       
        projects.data
      )
    );
  } catch (error) {
   console.log(error);
  }
}

function* getJiraIssues(action) {
  try {
    const response = yield call(getJiraIssuesApi, action.payload);
    const issues = response.data;
    yield put(
      jiraIssuesApiResponseSuccess(       
        issues.data
      )
    );
  } catch (error) {
   console.log(error);
  }
}

export function* watchGetResourceList() {
  yield takeEvery(ResourceActionTypes.GET_RESOURCES, getResourceList);
}

export function* watchCreateResource() {
  yield takeEvery(ResourceActionTypes.SAVE_RESOURCES, createResource);
}

export function* watchGetResourceById() {
  yield takeEvery(ResourceActionTypes.GET_RESOURCE_BY_ID, getResourceById);
}

export function* watchDeleteResourceById() {
  yield takeEvery(ResourceActionTypes.DELETE_RESOURCE, deleteResource);
}

export function* watchUpdateResourceById() {
  yield takeEvery(ResourceActionTypes.UPDATE_RESOURCE, updateResourceById);
}

export function* watchgetJiraProjects() {
  yield takeEvery(ResourceActionTypes.GET_JIRA_PROJECTS, getJiraProjects);
}

export function* watchgetJiraIssues() {
  yield takeEvery(ResourceActionTypes.GET_JIRA_ISSUES, getJiraIssues);
}


function* resourceSaga() {
  yield all([
    fork(watchGetResourceList),
    fork(watchCreateResource),
    fork(watchGetResourceById),
    fork(watchDeleteResourceById),
    fork(watchUpdateResourceById),
    fork(watchgetJiraProjects),
    fork(watchgetJiraIssues),
  ]);
}

export default resourceSaga;
