import { Button, ButtonGroup } from "react-bootstrap";


export const CmdBottomToolbar = ({visibility}) => {
  return (
    <div className="d-flex justify-content-start mt-1 ms-2">

      { visibility ? (
          <>
            <i className="mdi mdi-comment-plus-outline ms-1 me-1" style={{fontSize: 'smaller'}}></i>
            <i className="mdi mdi-trash-can-outline ms-1 me-1" style={{fontSize: 'smaller'}}></i>
            <i className="mdi mdi-share-variant-outline ms-1 me-1" style={{fontSize: 'smaller'}} ></i>
          </>
      ) : (
          <>
            <i className="mdi mdi-share-variant-outline ms-1 me-1" style={{fontSize: 'smaller', opacity: 0}} ></i>
          </>
      )}
    </div>
  ); 
}