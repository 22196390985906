import CardTitle from "../../../../components/CardTitle";

export const Recent = () => {
  return (
    <>
      <div className="p-2">
        <CardTitle title={"Activity"}></CardTitle>
      </div>
      <hr className="bg-dark-lighten m-0 mb-1" />
    </>
  );
}
export const Vars = () => (
  <>
    <div className="p-2">
      <CardTitle title={"Vars"}></CardTitle>
    </div>
    <hr className="bg-dark-lighten m-0 mb-1" />
  </>
);
export const Jira = () => (
  <>
    <div className="p-2">
      <CardTitle title={"Jira"}></CardTitle>
    </div>
    <hr className="bg-dark-lighten m-0 mb-1" />
  </>
);
export const Discussion = () => (
  <>
    <div className="p-2">
      <CardTitle title={"Discussion"}></CardTitle>
    </div>
    <hr className="bg-dark-lighten m-0 mb-1" />
  </>
);