// @flow
import { combineReducers } from "redux";
import assistantChatReducer from './assistantchat/reducers';

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import VarItems from "./trs/reducers";
import SettingsItems from "./settings/reducers";
import ResourceReducer from "./resources/reducers"
import sdocsReducer from "./ctx/reducers";
import ctxReducer from "./ctx/reducers";
import UserActivityReducer from "./user/reducers";
import CommandSequence from "./commandSequence/reducers";

export default (combineReducers({
  Auth,
  Layout,
  SettingsItems,
  VarItems,
  ResourceReducer,
  chat: assistantChatReducer,
  ctx: ctxReducer,
  UserActivityReducer,
  CommandSequence,
}): any);
