import { getDefaultHostDetails } from "../ctx/utils";

export const ResourceTypesEnum = {
  kShellSession: 'kShellSession',
  kShellCommand: 'kShellCommand',
  kAwsEc2Access: 'kAwsEc2Access',
  kKubernetesCli: 'kKubernetesCli',
  kAwsCli: 'kAwsCli',
  kPostgresDB: 'kPostgresDB',
  kMongoDB: 'kMongoDB',
  kAwsAuroraDB: 'kAwsAuroraDB',
  kRestEndpoint: 'kRestEndpoint',
  kKafka: 'kKafka',
  kJfrogAccount: 'kJfrogAccount',
  kCircleCIAccount: 'kCircleCIAccount',
  kAwsS3Location: 'kAwsS3Location',
  kElasticLogs: 'kElasticLogs',
  kLoki: 'kLoki',
};

export const resourceNames = [
  { resourceId: 'devops-bastion-host-id', resourceName: 'devops-bastion-host', resourceType: ResourceTypesEnum.kShellCommand },
  { resourceId: 'prod-loki-id', resourceName: 'prod-loki', resourceType: ResourceTypesEnum.kLoki},
  { resourceId: getDefaultHostDetails().hostId, resourceName: getDefaultHostDetails().hostname, resourceType: ResourceTypesEnum.kShellCommand },
  { resourceId: 'prod-customer-pg-id', resourceName: 'prod-customer-pg', resourceType: ResourceTypesEnum.kPostgresDB },
  { resourceId: 'prod-ingestion-activity-aurora-id', resourceName: 'prod-ingestion-activity-aurora', resourceType: ResourceTypesEnum.kAwsAuroraDB },
  { resourceId: 'prod-sanctum-s3-id', resourceName: 'prod-sanctum-s3', resourceType: ResourceTypesEnum.kAwsS3Location },
];
