export const TrsActionTypes = {
  ADD_VAR_ITEM: "ADD_VAR_ITEM",
  GET_USER_GROUPS: "GET_USER_GROUPS",
  GET_USER_GROUPS_SUCCESS: "GET_USER_GROUPS_SUCCESS",
  GET_USER_GROUPS_ERROR: "GET_USER_GROUPS_ERROR",
  SHARE_TRS: "SHARE_TRS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  CREATE_PLAYBOOK: "CREATE_PLAYBOOK",
  SAVE_API_RESULT: "SAVE_API_RESULT"
};
