import React, { useState } from "react";
import {
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

export const GRANT_ROLES = {
  kAllowedWithDataRestrictions: "Viewer",
  kAllowedFullView: "Viewer All",
  kEdit: "Editor",
};

const GRANT_ROLES_INFO = {
  kAllowedWithDataRestrictions: "View permitted results",
  kAllowedFullView: "Viewer all results",
  kEdit: "Add content",
};

const AccessMenu = (props) => {
  const { item, index, updateAccess } = props;
  const [role, setRole] = useState("kAllowedWithDataRestrictions");

  const handleRoleChange = (newRole) => {
    setRole(newRole);
    updateAccess(index, newRole);
  }
  return (
    <ListGroup.Item
      key={item.id}
      className={`d-flex justify-content-between align-items-center p-0 border-0 ${
        index > 0 && "mt-2"
      }`}
    >
      <div className="d-flex align-items-center">
        {!item?.profilePic ? (
          <div className="avatar-xs">
            <span className="avatar-title bg-secondary-lighten text-secondary font-12 rounded-circle fw-bold">
              {item?.name?.charAt(0)}
            </span>
          </div>
        ) : (
          <span className="account-user-avatar">
            <img
              src={item?.profilePic}
              className="rounded-circle"
              alt={props.username}
            />
          </span>
        )}
        <span className="text-body fw-semibold ps-1">{item.name}</span>{" "}
      </div>

      <DropdownButton
        variant="outline-secondary"
        className="btn-grant-access"
        title={GRANT_ROLES[role]}
      >
        {Object.keys(GRANT_ROLES).map((itemKey) => (
          <Dropdown.Item
            href="#"
            key={itemKey}
            onClick={() => handleRoleChange(itemKey)}
          >
            <Row>
              <Col className="col-1 p-0">
                {itemKey === role && (
                  <i className="uil uil-check font-16 text-primary" />
                )}
              </Col>
              <Col className="col-10">
                {GRANT_ROLES[itemKey]}{" "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{GRANT_ROLES_INFO[itemKey]}</Tooltip>}
                >
                  <i className="uil uil-info-circle" />
                </OverlayTrigger>
              </Col>
            </Row>
          </Dropdown.Item>
        ))}
        <Dropdown.Divider className="access-menu-divider"/>
        <Dropdown.Item>Add Expiry</Dropdown.Item>
        <Dropdown.Item>Remove Access</Dropdown.Item>
      </DropdownButton>
    </ListGroup.Item>
  );
};

export default AccessMenu;
