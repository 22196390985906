import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { constructCommandNode } from "../utils";
import { useDispatch } from "react-redux";
import { updateSdocAddNode } from "../../../../redux/actions";
import { resourceNames } from "../../data/constants";

const ShellCommand = ({shellCommandDetails, onAdd}) => {
  return (
    <>
      <div className="p-1 rounded" style={{backgroundColor: 'rgb(49,58,70)'}}>
        <div className="w-100 p-1 rounded" style={{backgroundColor: 'rgba(49,58,70, 0.8)'}}>
          <code style={{color: '#dddddd'}}>{shellCommandDetails.command}</code>
        </div>

        {
          shellCommandDetails.saveAs ? (
            <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid grey', paddingTop: '5px' }}>
              <span style={{ color: 'grey', marginLeft: '8px' }}>save result as:</span>
              <input
                type="text"
                value={shellCommandDetails.saveAs}
                style={{
                  backgroundColor: 'rgba(250,250,250, 0.1)', // Slightly lighter than the surrounding box
                  border: 'none',
                  color: '#dddddd',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  marginLeft: '8px',
                  lineHeight: '8px',
                }}
              />
            </div>
          ) : (
            <></>
          )
        }        

      </div>
      <Row className="g-2">
          <Col md>
              <FloatingLabel controlId="floatingInputGrid" label="Select Resource:">
                  <Form.Select size="sm" aria-label="Floating label select example"
                    style={{backgroundColor: 'transparent', border: '0px solid transparent',
                            lineHeight: '12px', fontSize: '12px'}} >
                      <option>dev-bastion-host</option>
                      <option value="1">personal-mac-host</option>
                      <option value="3">staging-bastion-host</option>
                  </Form.Select>
              </FloatingLabel>
          </Col>
          <Col md>
              <FloatingLabel controlId="floatingSelectGrid" label="Select target shell">
                  <Form.Select  size="sm" aria-label="Floating label select example"
                    style={{backgroundColor: 'transparent', border: '0px solid transparent',
                            lineHeight: '12px', fontSize: '12px'}} >
                      <option>TrShell #f809</option>
                      <option value="1">TrShell #f810 </option>
                      <option value="2">Cloudshell #f812</option>
                  </Form.Select>
              </FloatingLabel>
          </Col>
          <Col xs='2'>
            <Button variant="link" onClick={onAdd}> Add </Button>
          </Col>
      </Row>

      {/* <Button variant="link"
        onClick={() => dispatch(updateSdocAddNode(
          '1',
          constructCommandNode('', 'from model')
        ))}>
          Add to Trshell
      </Button>  */}
    </>
  );

}


export const FormattedModelResponse = ({formattedResponse}) => {  
  const dispatch = useDispatch();

  // Function to render markdown text
  const renderMarkdownText = (text) => {
    return <div>{text}</div>;
  };

  const addCommand = (shellCommandDetails, config) => {
    const {resourceId, targetType, targetId} = config;
    const selectedResourceName = resourceNames.find(r => r.resourceId === shellCommandDetails.resourceId).resourceName;
    const commandMainTextWithMentions = `@[${selectedResourceName}](${shellCommandDetails.resourceId}) \n${shellCommandDetails.command}`;
    const newCommandNode = constructCommandNode(shellCommandDetails.resourceId, commandMainTextWithMentions, '');
    dispatch(updateSdocAddNode('1', newCommandNode));
  }

  return (
    <>
      {formattedResponse.map((entry, index) => {
        switch (entry.type) {
          case "kMarkdownText":
            return renderMarkdownText(entry.markdownText);
            break;
          case "kShellCommand":
            return <ShellCommand shellCommandDetails={entry.shellCommandDetails}
                                 onAdd={(config) => addCommand(entry.shellCommandDetails, config)}/>;
            break;
          default:
            return <>Type {entry.type} not supported</>;
        }
      })}

      <Button variant="link" size="sm" className="p-0 mt-1">
        Add all commands
      </Button>
    </>
  );
};