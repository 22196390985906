import React from 'react';
import ReactFlow, { MiniMap, Controls } from 'react-flow-renderer';

const VerticalStackedNodes = () => {
  const elements = [
    { id: '1', type: 'default', data: { label: 'Node 1' }, position: { x: 100, y: 100 } },
    { id: '2', type: 'default', data: { label: 'Node 2' }, position: { x: 100, y: 200 } },
    { id: '3', type: 'default', data: { label: 'Node 3' }, position: { x: 100, y: 300 } }
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <ReactFlow
        elements={elements}
        nodeTypes={{
          default: ({ data }) => (
            <div style={{ width: 100, height: 50, backgroundColor: '#fff', border: '1px solid #000', textAlign: 'center' }}>
              {data.label}
            </div>
          ),
        }}
        snapToGrid={true}
        snapGrid={[10, 10]}
        defaultZoom={1}
        defaultPosition={[0, 0]}
        style={{ backgroundColor: '#f0f0f0' }}
        onNodeDragStop={(event, node) => console.log('drag stop', node)}
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default VerticalStackedNodes;
