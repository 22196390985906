// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { TrsActionTypes } from "./constants";
import { GLOBAL_TENANT_ID, createPlaybookApi, getUserGroups as getUserGroupsApi, shareTrs as shareTrsApi } from "../../helpers/";
import {
  userGroupApiResponseSuccess,
  userGroupApiResponseError,
  apiResultTrsShell,
} from "./actions";

/**
 * Get the user groups
 *
 */
function* getUserGroups() {
  try {
    const response = yield call(getUserGroupsApi, {
      tenantId: GLOBAL_TENANT_ID,
    });
    const userGroups = response.data;
    yield put(
      userGroupApiResponseSuccess(       
        userGroups.data
      )
    );
  } catch (error) {
    yield put(
      userGroupApiResponseError(TrsActionTypes.GET_USER_GROUPS_ERROR, error)
    );
  }
}

/**
 * Share Trs
 *
 */
function* shareTrs(action) {
  try {
    yield call(shareTrsApi, action.payload);    
  } catch (error) {
    yield put(
      userGroupApiResponseError(TrsActionTypes.GET_USER_GROUPS_ERROR, error)
    );
  }
}

/**
 * Create Playbook
 *
 */
function* createPlaybook(action) {
  try {
    const response = yield call(createPlaybookApi, action.payload);    
    if (response?.data?.errorCode !== "kNoError") {
      yield put(apiResultTrsShell({ result: "error", msg: "Failed to create Playbook." }));  
    } else {
      yield put(apiResultTrsShell({ result: "success", msg: "Playbook successfully created." }));
    }
  } catch (error) {
    console.log(error);
    yield put(apiResultTrsShell({ result: "error", msg: "Failed to create Playbook." }));
  }
}

export function* watchUserGroups() {
  yield takeEvery(TrsActionTypes.GET_USER_GROUPS, getUserGroups);
}

export function* watcShareTrs() {
  yield takeEvery(TrsActionTypes.SHARE_TRS, shareTrs);
}

export function* watchcreatePlaybook() {
  yield takeEvery(TrsActionTypes.CREATE_PLAYBOOK, createPlaybook);
}

function* trsSaga() {
  yield all([fork(watchUserGroups), fork(watcShareTrs), fork(watchcreatePlaybook)]);
}

export default trsSaga;
